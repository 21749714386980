import React, { useState, useEffect } from "react";
import _ from "lodash";

import { get, putForm } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";
import ConditionModifyComponent from "../../../components/admin/conditions/ConditionModifyComponent";

export default function ConditionModifyPage( props ) {
    const [conditionLoaded, setConditionLoaded] = useState(false);
    const [condition, setCondition] = useState(null);
    const [name, setName] = useState("");
    const [id, setId] = useState();
    const [conditionError, setConditionError] = useState();
    const [updateError, setUpdateError] = useState();
    const [working, setWorking] = useState(false);

    useEffect(() => {
        setConditionLoaded(false);

        get("/condition/read_one.php", {id: props.match.params.id}, true)
        .then(response => {

            setConditionLoaded(true);

            const handled_requests = [200, 400, 404, 500];

            if (handled_requests.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unexpected response: ${response.status}`);
            }
        })
        .then(data => {

            const condition_data = data.payload;

            if (data.code === 200) {
                setCondition(condition_data);
                setName(condition_data.name);
                setId(condition_data.id);
                return condition_data;
            } else if (data.code === 400) {
                setConditionError("Klaidinga užklausa");
            } else if (data.code === 404) {
                setConditionError("Kategorija nerastas");
            } else if (data.code === 500) {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("500: Internal Server Error");
                }
            }
            else {
                throw new Error("Faulty response data");
            }

        })
        .catch(e => {
            setConditionError("Netikėta klaida: " + e.message);
        })

    }, []);

    function handleSubmit(e) {
        e.preventDefault();

        var data = {
            id: id,
            name: name,
            jwt: props.auth.JWT
        }

        setWorking(true);

        putForm("condition/update.php", encodeFormData(data))
        .then(response => {
            setWorking(false);
            return response.json();
        })
        .then(data => {
            if (data.code === 200) {
                props.history.goBack();
            } else if (!_.isEmpty(data.error)) {
                throw new Error(data.error);
            } else {
                throw new Error("Serverio klaida.");
            }
        })
        .catch(e => {
            setUpdateError("Netikėta klaida: " + e.message);
        });
    }

    return (
        <ConditionModifyComponent
            condition={condition}
            name={name}
            setName={setName}
            handleSubmit={handleSubmit}
            conditionLoaded={conditionLoaded}
            updateError={updateError}
            conditionError={conditionError}
            working={working}
            props={props}
        />
    );
}