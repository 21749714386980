import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const productConditions = createSlice({
    name: "productConditions",
    initialState: {
        conditions: [],
        condition: 0,
    },
    reducers: {
        setProductConditions: (state, action) => {
            state.conditions = [
                {id: 0, name: "VISOS BŪKLĖS"},
                ...action.payload,
                {id: -1, name: "BE BŪKLĖS"}
            ];
        },
        setProductCondition: (state, action) => {
            state.condition = _.parseInt(action.payload);
        },
    }
});

export const selectProductConditions = (state) => state.productConditions.conditions;
export const selectProductCondition = (state) => state.productConditions.condition;
export default productConditions.reducer;
export const { setProductConditions, setProductCondition } = productConditions.actions;