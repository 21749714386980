import "../GuestStyle.css";
import "./ProductStyle.css";
import React from "react";
import * as Icon from "react-bootstrap-icons";
import ContentLoader from "react-content-loader";
import _ from "lodash";

import ImageGallery from "../../../containers/shared/image/ImageGallery";

export default function ProductPreviewPageComponent({
  props,
  product,
  loadComplete,
  loadError,
  conditions,
  categories,
}){

  const images = product ? product.images : [];

  return (
    <section className="product-preview container starter-template" role="main">

      <div id="response">
        {loadError ? <div className='alert alert-danger'>{loadError}</div> : ""}
      </div>

      <div className="pane image-pane">
        <ImageGallery images={images} loadComplete={loadComplete}/>
      </div>

      { product ?
        <div className="pane info-pane">
          <h3>{product.title.toUpperCase()}</h3>

          {product.discount && product.discount > 0 ?
            (
              <>
                <del><h4>{product.price}€</h4></del>
                <h4>{product.price - product.discount}€</h4>
              </>
            ):
            <h4>{product.price}€</h4>
          }

          <p className="description">{product.description}</p>


          <div className="condition-container">
          {
            product.condition_id != -1 && !_.isEmpty(conditions[product.condition_id]) ?
              <span>
                <Icon.ClipboardCheck />
                {" : "}
                <span className="inner-text">
                  {`${conditions[product.condition_id].toUpperCase()}`}
                </span>
              </span>
            : ""
          }

          {
            product.category_id != -1 && !_.isEmpty(categories[product.category_id]) ?
              <span>
                <Icon.ListUl />
                {" : "}
                <span className="inner-text">
                  {`${categories[product.category_id].toUpperCase()}`}
                </span>
              </span>
            : ""
          }
          </div>


        </div> :
        <div className="pane info-pane">
          <ContentLoader speed={2} backgroundColor="rgb(230, 230, 230)" foregroundColor="rgb(245, 245, 245)">
            <rect x="0" y="0" rx="4" ry="4" width="250" height="25" />
            <rect x="0" y="35" rx="4" ry="4" width="40" height="25" />
            <rect x="0" y="80" rx="4" ry="4" width="250" height="15" />
            <rect x="0" y="100" rx="4" ry="4" width="200" height="15" />
            <rect x="0" y="120" rx="4" ry="4" width="275" height="15" />

          </ContentLoader>
        </div>
      }

    </section>
  );
}