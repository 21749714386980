import "../AdminStyle.css";
import "./UsersStyle.css";
import React from "react";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";

export default function UserCard({
  index,
  user,
  props,
  onRemove,
  setAdmin,
  adminUpdateWorking,
}) {

  return (
    <div className="user-card" key={index}>

      <div className="user-picture-container">

        <div className="user-picture">
          <Icon.PersonCircle />
        </div>

      </div>

      <div className="user-info-container">

        <div className="user-name-container info-item">
          <h6>{`${user.firstname} ${user.lastname}`}</h6>
        </div>

        <div className="user-email-container info-item">
          <h6>{user.email}</h6>
        </div>

        <hr className="info-item" />

        <div className="admin-status-container info-item">

          <label
            className="admin-status-item"
            for={`is-admin-${index}`}
          >
            <span>Administratorius:</span>
          </label>

          {
            adminUpdateWorking.includes(user.id) ?
              <span
                className="spinner-border spinner-border-sm admin-status-item spinner"
                role="status"
                aria-hidden="true"
              ></span> :
              <input
                className="admin-status-item"
                type="checkbox"
                id={`is-admin-${index}`}
                name="is-admin"
                defaultChecked={user.is_admin}
                onChange={(e) => setAdmin(user.id, e)}
                disabled={adminUpdateWorking.includes(user.id)}
              />
          }

        </div>

        <hr className="info-item" />

        <div className="actions-container">
          <Button
            className="btn-danger"
            onClick={onRemove}
          >
            <span>IŠTRINTI</span>
          </Button>
        </div>

      </div>

    </div>
  );
}