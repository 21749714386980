import "../AdminStyle.css";
import "./ProductsStyle.css";
import React from "react";
import { FormControl } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import ProductInfoForm from "./ProductInfoForm";
import LoadingComponent from "../../shared/loading/LoadingComponent";

export default function ProductCreateComponent({
  title,
  setTitle,
  description,
  setDescription,
  price,
  setPrice,
  discount,
  setDiscount,
  categoryId,
  setCategoryId,
  categories,
  handleSubmit,
  loadComplete,
  errorMessage,
  working,
  props,
  resizeAndSetImages,
  conditionLoadComplete,
  conditions,
  conditionId,
  setConditionId,
  settingImages,
  imageError
})
{

  return (
    <div className="container container-admin">

      <div id="response">
        {errorMessage ? <div className='alert alert-danger'>{errorMessage}</div> : ""}
        {imageError ? <div className='alert alert-danger'>{imageError}</div> : ""}
      </div>

      {
        !loadComplete && !conditionLoadComplete ?
        <LoadingComponent /> :
          <>

            <div className="page-header">
              <h2>SUKURTI NAUJĄ</h2>
              <hr/>
            </div>

            <form onSubmit={handleSubmit} id="product-info-form">
              <ProductInfoForm
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
                price={price}
                setPrice={setPrice}
                discount={discount}
                setDiscount={setDiscount}
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                categories={categories}
                conditionId={conditionId}
                setConditionId={setConditionId}
                conditions={conditions}
                handleSubmit={handleSubmit}
              />

              <div className="upload-images-form">
                <FormControl
                  id="imageForm"
                  type="file"
                  label="File"
                  multiple
                  onChange={e => resizeAndSetImages(e.target.files)}
                />
              </div>

              <div id="btn-div">

                <button className="btn btn-secondary" onClick={props.history.goBack}>
                  <Icon.ChevronLeft className="button-icon" />
                  GRĮŽTI
                </button>

                <button className="btn btn-primary" disabled={working || settingImages}>
                  {(working || settingImages) ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ""}
                  <span className="font-weight-normal" >PATVIRTINTI</span>
                </button>

              </div>
            </form>

            <footer className="admin-spacer" />
          </>
      }
    </div>
  );

}