import { createSlice } from "@reduxjs/toolkit";

export const productCategories = createSlice({
    name: "productCategories",
    initialState: {
        categories: [],
    },
    reducers: {
        setProductCategories: (state, action) => {
            state.categories = [
                {id: 0, name: "VISOS PREKĖS"},
                ...action.payload,
                {id: -1, name: "BE KATEGORIJOS"}
            ];
        },
    }
});

export const selectProductCategories = (state) => state.productCategories.categories;
export default productCategories.reducer;
export const { setProductCategories } = productCategories.actions;