import "../AdminStyle.css";
import "./ProductsStyle.css";
import React from "react";

import ErrorPage from "../../shared/error/ErrorPage";
import LoadingComponent from "../../shared/loading/LoadingComponent";
import ImageGallery from "../../../containers/shared/image/ImageGallery";

export default function ProductInfoComponent({
  product,
  categories,
  productComplete,
  categoriesComplete,
  error,
  props,
  conditions,
}) {

  const images = product ? product.images : [];

  return (

    <div className="container">

        {productComplete && categoriesComplete ? getProductDisplay(product, categories, error, props, productComplete, images, conditions) : <LoadingComponent />}

    </div>
  );

}

function getProductDisplay(product, categories, error, props, productComplete, images, conditions) {

  if (error) {
    return <ErrorPage errorMessage={error} goBack={props.history.goBack}/>
  }

  if (!product) {
    return (<h2>Produktas nerastas</h2>);
  }

  return (
    <div className="container container-admin">
      <div className="page-header">
        <h1>{product.title}</h1>
      </div>

      <div className="page-section">
        <b><p className="description">{product.description}</p></b>
      </div>

      <div className="page-section">
        <p><b>Kaina:</b> {product.price}€</p>
      </div>

      <div className="page-section">
        <p><b>Nuolaida:</b> {product.discount}€</p>
      </div>

      <div className="page-section">
        <p><b>Kategorija:</b> {categories && categories[product.category_id] ? categories[product.category_id] : "Kategorija nerasta"}</p>
      </div>

      <div className="page-section">
        <p><b>Būklė:</b> {conditions && conditions[product.condition_id] ? conditions[product.condition_id] : "Būklė nerasta"}</p>
      </div>

      <div className="page-section">
        <p><b>Sukurta:</b> {product.created}</p>
      </div>

      <div className="page-section">
        <p><b>Modifikuota:</b> {product.modified}</p>
      </div>

      <div className="page-section product-info image-gallery">

        <ImageGallery
            images={images}
            loadComplete={productComplete}
        />

      </div>

      <button className="btn btn-primary" onClick={props.history.goBack}>Grįžt atgal</button>
      <footer className="admin-spacer" />
    </div>
  );
}