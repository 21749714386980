import "../GuestStyle.css";
import "./HeaderStyle.css";
import React from "react";
import { Navbar, Nav, Container, Form, FormControl, Button } from "react-bootstrap";
import ROUTES from "../../../app/routes";

export default function HeaderComponent({props, searchText, setSearchText, goTo, goToFooter, headerRef})
{

  return(
    <header role="main" className="header-header" ref={headerRef}>

      <Navbar id="header-navbar" bg="light" expand="lg">
        <Container>

          <Navbar.Brand onClick={e => goTo("/")}>
            <img
              src="/graphics/logos/audriaus_baldai_logo_text_side.png"
              width="200"
              height="60"
              className="d-inline-block align-top"
              alt=""
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="me-auto">
              <Nav.Link onClick={e => goTo("/")} >PAGRINDINIS</Nav.Link>
              <Nav.Link onClick={e => goTo(ROUTES.productsListRoute())}>BALDAI</Nav.Link>
              <Nav.Link onClick={e => goToFooter()}>KONTAKTAI</Nav.Link>
            </Nav>

            <Form
              className="d-flex"
              onSubmit={e => {
                e.preventDefault();
                if (!searchText) return;
                goTo(ROUTES.productsSearchRoute(searchText, 1));
                setSearchText("");
              }}
            >
              <FormControl
                type="search"
                placeholder="Ieškoti"
                className="mr-2"
                aria-label="Ieškoti"
                value={searchText}
                onChange={e => setSearchText(e.currentTarget.value)}
              />

              <Button
                className={!searchText ? "disabled" : ""}
                type="submit"
                variant="outline-success"
              >
                Ieškoti
              </Button>

            </Form>

          </Navbar.Collapse>

        </Container>
      </Navbar>

    </header>
  );
}