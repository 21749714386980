import React, { useState, useEffect } from "react";
import ConditionCreateComponent from "../../../components/admin/conditions/ConditionCreateComponent";
import { get, postForm } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";

export default function ConditionCreatePage(props) {
    const [name, setName] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [working, setWorking] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();

        var data = {
            name: name,
            jwt: props.auth.JWT,
        }

        setErrorMessage("");
        setWorking(true);

        postForm("condition/create.php", encodeFormData(data))
        .then(response => {
            setWorking(false);

            var accepted_response_codes = [201, 400, 401, 500, 503];

            if (accepted_response_codes.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unexpected response code: ${response.status}`);
            }
        })
        .then(data => {

            if (data.code === 201) {
                props.history.goBack();
            } else if (data.code === 400) {
                setErrorMessage("Klaidinga užklausa. Patikrinkite įvestus duomenis.");
            } else if (data.code === 401) {
                setErrorMessage("Klaidingi prisijungimo duomenys.");
            } else if (data.code === 500) {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Serverio klaida.");
                }
            } else if (data.code === 503) {
                throw new Error("Nepavyko sukurti kategorijos.");
            } else {
                throw new Error("Faulty response data");
            }
        })
        .catch(e => {
            setErrorMessage("Netikėta klaida: " + e.message);
        });
    }

    return (
        <ConditionCreateComponent
            name={name}
            setName={setName}
            errorMessage={errorMessage}
            working={working}
            props={props}
            handleSubmit={handleSubmit}
        />
    );
}