import "../AdminStyle.css";
import "./ProductsStyle.css";
import React from "react";
import { Link } from "react-router-dom";

import ROUTES from "../../../app/routes";
import ProductCardComponent from "../../guest/product/ProductCardComponent";

export default function ProductCardAdminComponent({
    props,
    key,
    product,
    productCardRefs,
    openProduct,
    removeProduct,
}) {
  return (
    <div className="product-card-container" key={key}>

      <ProductCardComponent
        product={product}
        openProduct={e => openProduct(product.id)}
        productCardRefs={productCardRefs}
      />

      <div className="product-card-buttons">

        <Link to={ROUTES.adminProductsModifyRoute(product.id)} >
          <button className="btn btn-primary m-r-1em">REDAGUOTI</button>
        </Link>

        <button
          className="btn btn-danger m-r-1em"
          onClick={() => removeProduct(product.id)}
        >
          IŠTRINTI
        </button>

      </div>

    </div>
  );
}