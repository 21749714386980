import "../GuestStyle.css";
import "./HomeStyle.css";
import React from "react";

import MapComponent from "../map/MapComponent";
import LatestProducts from "../../../containers/guest/product/LatestProducts";

export default function HomePageComponent({props, pageData})
{
  return(
    <main role="main" className="container starter-template">
      <LatestProducts props={props}/>
      <MapComponent props={props} pageData={pageData}/>
    </main>
  );
}