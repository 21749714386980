import { createSlice } from "@reduxjs/toolkit";
import { setCookie, getCookie, deleteCookie} from "../../utils/cookie";
import _ from "lodash";

export const auth = createSlice({
    name: 'auth',
    initialState: {
        auth: {
            JWT: getCookie("jwt"),
        },
    },
    reducers: {
        setJWT: (state, action) => {
            setCookie("jwt", action.payload, 1);

            if (_.isEmpty(action.payload)) {
                deleteCookie("jwt");
            }

            state.auth["JWT"] = action.payload;
        },
        setLoggedIn: (state, action) => {
            state.auth["loggedIn"] = action.payload;
        },
        setLoginComplete: (state, action) => {
            state.auth["loginComplete"] = action.payload;
        },
        setUserData: (state, action) => {
            state.auth["userData"] = action.payload;
        }
    }
});

export const selectAuth = (state) => state.auth.auth;
export default auth.reducer;
export const { setJWT, setLoggedIn, setLoginComplete, setUserData } = auth.actions;