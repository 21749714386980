import "../AdminStyle.css";
import "./ProductsStyle.css";
import React from "react";
import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';

import "../AdminStyle.css";
import ROUTES from "../../../app/routes";
import PagingButtons from "../../shared/utils/PagingButtons";
import ListSelect from "../../shared/utils/ListSelect";
import ProductViewComponent from "./ProductViewComponent";
import { __ } from "../../../utils/lolodash";

export default function ProductsComponent({
  props,
  products,
  loadComplete,
  removeProduct,
  removeError,
  pages,
  currentPage,
  paging,
  goToPage,
  productCardRefs,
  openProduct,

  category,
  categories,
  showCategories,
  setShowCategories,
  goToCategory,

  condition,
  conditions,
  setCondition,
  showConditions,
  setShowConditions,

  sorting,
  sortBy,
  setSortBy,
  showSorting,
  setShowSorting,
})
{

  return (

    <div className="container product-list container-admin">

      <div className="page-header">

        <div className="header-title-bar">

          <div className="title">
            <h1>PREKĖS</h1>
          </div>

          <div className="buttons">
            <Link to={ROUTES.adminProductsCreateRoute()} >
              <button className="btn btn-success m-r-1em">
                <Icon.Plus className="button-icon" />
              </button>
            </Link>
          </div>

        </div>

        <div id="response">
          {removeError ? <div className='alert alert-danger'>{removeError}</div> : ""}
        </div>

        <hr />

      </div>

      <div className="filter-container">

        <div className="filter-title">
          {
            category === 0 ?
              <h5>VISOS PREKĖS</h5> :
              <h5>{__.findEntry(categories, category)}</h5>
            }
        </div>

        <div className="filter-buttons">

          <div className={`icon ${category !== 0 ? "icon-active" : ""}`}>
            <Icon.ListUl
              onClick={() => setShowCategories(!showCategories)}
            />
          </div>

          <div className={`icon ${condition !== 0 ? "icon-active" : ""}`}>
            <Icon.ClipboardCheck
              onClick={() => setShowConditions(!showConditions)}
            />
          </div>

          <div className={`icon ${sortBy !== 0 ? "icon-active" : ""}`}>
            <Icon.SortDown
              onClick={() => setShowSorting(!showSorting)}
            />
          </div>

        </div>

      </div>

      <div className="filter-select-container">
        <ListSelect
          title="KATEGORIJOS"
          show={showCategories}
          options={categories}
          onSelect={goToCategory}
          currentItem={category}
        />

        <ListSelect
          title="BŪKLĖS"
          show={showConditions}
          options={conditions}
          onSelect={setCondition}
          currentItem={condition}
        />

        <ListSelect
          title="RIKIAVIMAS"
          show={showSorting}
          options={sorting}
          onSelect={setSortBy}
          currentItem={sortBy}
        />
      </div>

      <ProductViewComponent
        props={props}
        products={products}
        openProduct={openProduct}
        removeProduct={removeProduct}
        loadComplete={loadComplete}
        productCardRefs={productCardRefs}
      />

      <div className="paging-container">
        <PagingButtons
          props={props}
          pages={pages}
          currentPage={currentPage}
          paging={paging}
          goTo={goToPage}
        />
      </div>

      <div id="btn-div" className="page-section">
        <Link to={ROUTES.adminDashboardRoute()} >
          <button className="btn btn-secondary m-r-1em">
            <Icon.ChevronLeft className="button-icon" />
            GRĮŽTI
          </button>
        </Link>
      </div>

      <footer className="admin-spacer" />
    </div>
  );

}