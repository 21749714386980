import _ from "lodash";

const find = (collection, predicate, default_value=null) => {
    return _.find(collection, predicate) || default_value;
}

const findEntry = (collection, id) => {
    return find(
        collection, {id: id},
        {id: -2, name: "NENURODYTA"}
    ).name.toUpperCase();
}

export const __ = {
    find: find,
    findEntry: findEntry,
}