import "../AdminStyle.css";
import "./ConditionsStyle.css";
import React from "react";

import ConditionInfoForm from "./ConditionInfoForm";

export default function ConditionCreateComponent({
  name,
  setName,
  handleSubmit,
  errorMessage,
  working,
  props,
})
{

  return (
    <div className="container container-admin">

      <div id="response">
        {errorMessage ? <div className='alert alert-danger'>{errorMessage}</div> : ""}
      </div>

      <div className="page-header">
        <h2>NAUJA BŪKLĖ</h2>
        <hr/>
      </div>

      <ConditionInfoForm
        name={name}
        setName={setName}
        handleSubmit={handleSubmit}
        working={working}
        props={props}
      />

      <footer className="admin-spacer" />

    </div>
  );

}