import "../AdminStyle.css";
import "./PageInfoStyle.css";
import React from "react";
import * as Icon from "react-bootstrap-icons";

import LoadingComponent from "../../shared/loading/LoadingComponent";

export default function PageInfoComponent({
    props,
    loadComplete,
    loadError,
    updateError,
    working,
    success,
    update,
    pageName,
    setPageName,
    pageDescription,
    setPageDescription,
    contactPerson,
    setContactPerson,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    addressLine3,
    setAddressLine3,
    googleMapsLink,
    setGoogleMapsLink
  }) {

  return (

    <div className="container container-admin">

      <div className="page-header">
        <h1>SVETAINĖS INFORMACIJA</h1>

        <div id="response">
          {loadError ? <div className='alert alert-danger'>{loadError}</div> : ""}
          {updateError ? <div className='alert alert-danger'>{updateError}</div> : ""}
          {success ? <div className='alert alert-success'>{success}</div> : ""}
        </div>

        <hr />

      </div>

      {!loadComplete ?
        <LoadingComponent /> :
        <>
          <div className="panel panel-default">

          <div className="panel-body">

            <h3>Pradžia</h3>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control input-lg"
                id="pageName"
                placeholder="Svetainės Pavadinimas"
                value={pageName}
                onChange={e => setPageName(e.currentTarget.value)}
              />
              <label for="pageName">Svetainės Pavadinimas</label>
            </div>

            <div className="form-floating mb-3">
              <textarea
                id="page-description"
                className="form-control input-lg"
                placeholder="Svetainės Apibūdinimas"
                value={pageDescription}
                onChange={e => setPageDescription(e.currentTarget.value)}
              />
              <label for="page-description">Svetainės Apibūdinimas</label>
            </div>

          </div>

          <div className="panel-body">

            <h3>Kontaktai</h3>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control input-lg"
                id="contactPerson"
                placeholder="Kontakto Vardas ir Pavardė"
                value={contactPerson}
                onChange={e => setContactPerson(e.currentTarget.value)}
              />
              <label for="contactPerson">Kontakto Vardas ir Pavardė</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control input-lg"
                id="phoneNumber"
                placeholder="Telefono Numeris"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.currentTarget.value)}
              />
              <label for="phoneNumber">Telefono Numeris</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control input-lg"
                id="email"
                placeholder="El. Paštas"
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
              />
              <label for="email">El. Paštas</label>
            </div>

          </div>

          <div className="panel-body">
            <h3>Adresas</h3>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control input-lg"
                id="addressLine1"
                placeholder="Gatvė"
                value={addressLine1}
                onChange={e => setAddressLine1(e.currentTarget.value)}
              />
              <label for="addressLine1">Gatvė</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control input-lg"
                id="addressLine2"
                placeholder="Miestas bei pašto kodas"
                value={addressLine2}
                onChange={e => setAddressLine2(e.currentTarget.value)}
              />
              <label for="addressLine2">Miestas bei pašto kodas</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control input-lg"
                id="addressLine3"
                placeholder="Šalis"
                value={addressLine3}
                onChange={e => setAddressLine3(e.currentTarget.value)}
              />
              <label for="addressLine3">Šalis</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control input-lg"
                id="googleMapsPath"
                placeholder="Google Žemėlapių Nuoroda"
                value={googleMapsLink}
                onChange={e => setGoogleMapsLink(e.currentTarget.value)}
              />
              <label for="googleMapsPath">Google Žemėlapių Nuoroda</label>
            </div>

          </div>

        </div>

        <div id="btn-div">
          <button className="btn btn-secondary" onClick={props.history.goBack}>
            <Icon.ChevronLeft className="button-icon" />
            GRĮŽTI
          </button>

          <button className="btn btn-primary" disabled={working} onClick={update}>
            {working ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ""}
            <span className="font-weight-normal">IŠSAUGOTI</span>
          </button>
        </div>

        <footer className="admin-spacer" />

      </>
      }

    </div>
  );

}