import React, {useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth, } from "../features/auth/authSlice";
import PrivateRoute from "../containers/admin/auth/PrivateRoute";
import SignupPage from "../containers/admin/auth/SignupPage";
import LoginPage from "../containers/admin/auth/LoginPage";
import AdminDashboard from "../containers/admin/dashboard/AdminDashboard";
import GetSignupURLPage from "../containers/admin/dashboard/GetSignupURLPage";
import ProductsPage from "../containers/admin/products/ProductsPage";
import ProductInfoPage from "../containers/admin/products/ProductInfoPage";
import ProductModifyPage from "../containers/admin/products/ProductModifyPage";
import ProductCreatePage from "../containers/admin/products/ProductCreatePage";
import CategoriesPage from "../containers/admin/categories/CategoriesPage";
import CategoryCreatePage from "../containers/admin/categories/CategoryCreatePage";
import CategoryModifyPage from "../containers/admin/categories/CategoryModifyPage";
import UsersPage from "../containers/admin/users/UsersPage";
import PageInfoPage from "../containers/admin/pageInfo/PageInfoPage";
import ConditionsPage from "../containers/admin/conditions/ConditionsPage";
import ConditionCreatePage from "../containers/admin/conditions/ConditionCreatePage";
import ConditionModifyPage from "../containers/admin/conditions/ConditionModifyPage";
import NotFoundPage from "../components/shared/error/NotFoundPage";
import Header from "../containers/admin/header/Header";
import ProfileEditPage from "../containers/admin/users/ProfileEditPage";

export default function AppAdmin() {

  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const authProps = {
    auth: auth,
    dispatch: dispatch,
  }

  return (
    <Router>

      <Route path="/" component={(props) => <Header props={props} authProps={authProps} /> } />

      <Switch>

          <Route exact path="/admin/login" component={(props) => <LoginPage {...props} dispatch={dispatch} />} />
          <Route exact path="/admin/signup/:token?" component={SignupPage} />

          <PrivateRoute exact path="/admin" component={ (props) => <AdminDashboard {...props } {...authProps} /> } {...authProps} />
          <PrivateRoute exact path="/admin/getSignupURL" component={ (props) => <GetSignupURLPage {...props } {...authProps} /> } {...authProps} />
          <PrivateRoute exact path="/admin/profile/edit" component={ (props) => <ProfileEditPage {...props } {...authProps} /> } {...authProps} />

          <PrivateRoute exact path="/admin/products/list/:category/:page" component={ (props) => <ProductsPage {...props } {...authProps} /> } {...authProps} />
          <PrivateRoute exact path="/admin/products/preview/:id" component={ (props) => <ProductInfoPage {...props } {...authProps} /> } {...authProps} />
          <PrivateRoute exact path="/admin/products/modify/:id" component={ (props) => <ProductModifyPage {...props } {...authProps} /> } {...authProps} />
          <PrivateRoute exact path="/admin/products/create" component={ (props) => <ProductCreatePage {...props } {...authProps} /> } {...authProps} />

          <PrivateRoute exact path="/admin/categories" component={ (props) => <CategoriesPage {...props } {...authProps} /> } {...authProps} />
          <PrivateRoute exact path="/admin/categories/create" component={ (props) => <CategoryCreatePage {...props } {...authProps} /> } {...authProps} />
          <PrivateRoute exact path="/admin/categories/modify/:id" component={ (props) => <CategoryModifyPage {...props } {...authProps} /> } {...authProps} />

          <PrivateRoute exact path="/admin/conditions" component={ (props) => <ConditionsPage {...props } {...authProps} /> } {...authProps} />
          <PrivateRoute exact path="/admin/conditions/create" component={ (props) => <ConditionCreatePage {...props } {...authProps} /> } {...authProps} />
          <PrivateRoute exact path="/admin/conditions/modify/:id" component={ (props) => <ConditionModifyPage {...props } {...authProps} /> } {...authProps} />

          <PrivateRoute exact path="/admin/users" component={ (props) => <UsersPage {...props } {...authProps} /> } {...authProps} />

          <PrivateRoute exact path="/admin/pageInfo" component={ (props) => <PageInfoPage {...props } {...authProps} /> } {...authProps} />

          <Route component={() => <NotFoundPage backTo="/admin" />} />
      </Switch>
    </Router>
  );
}