import "../AdminStyle.css";
import "./DashboardStyle.css";
import React from "react";
import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';

import ROUTES from "../../../app/routes";

export default function AdminDashboardComponent()
{
  return(
    <main role="main" className="container dashboard-content">

      <div className="dashboard-section">

        <h4>INVENTORIUS</h4>
        <hr />

        <div className="section-content">
          <Link to={e => ROUTES.adminProductsRoute()}>
            <button type="button" className="btn btn-primary btn-lg btn-block btn-dashboard-menu">
              <div className="dashboard-button-content">
                <Icon.BoxSeam className="button-icon" />
                <span>PREKĖS</span>
              </div>
            </button>
          </Link>

          <Link to={ROUTES.adminCategoriesRoute}>
            <button type="button" className="btn btn-primary btn-lg btn-block btn-dashboard-menu">
              <div className="dashboard-button-content">
                <Icon.ListUl className="button-icon" />
                <span>KATEGORIJOS</span>
              </div>
            </button>
          </Link>

          <Link to={ROUTES.adminConditionsRoute}>
            <button type="button" className="btn btn-primary btn-lg btn-block btn-dashboard-menu">
              <div className="dashboard-button-content">
                <Icon.ClipboardCheck className="button-icon" />
                <span>BŪKLĖS</span>
              </div>
            </button>
          </Link>
        </div>

      </div>

      <div className="dashboard-section">

        <h4>VARTOTOJAI</h4>
        <hr />

        <div className="section-content">
          <Link to={ROUTES.adminUsersRoute}>
            <button type="button" className="btn btn-primary btn-lg btn-block btn-dashboard-menu">
              <div className="dashboard-button-content">
                <Icon.PeopleFill className="button-icon" />
                <span>VARTOTOJAI</span>
              </div>
            </button>
          </Link>

          <Link to={ROUTES.adminGetSignupURLRoute}>
            <button type="button" className="btn btn-primary btn-lg btn-block btn-dashboard-menu">
              <div className="dashboard-button-content">
                <Icon.Link45deg className="button-icon" />
                <span>REGISTRACIJOS NUORODA</span>
              </div>
            </button>
          </Link>
        </div>

      </div>

      <div className="dashboard-section">

        <h4>SVETAINĖ</h4>
        <hr />

        <div className="section-content">
          <Link to={ROUTES.adminPageInfoRoute}>
            <button type="button" className="btn btn-primary btn-lg btn-block btn-dashboard-menu">
              <div className="dashboard-button-content">
                <Icon.InfoCircleFill className="button-icon" />
                <span>SVETAINĖS INFORMACIJA</span>
              </div>
            </button>
          </Link>
        </div>

      </div>

    </main>
  );
}