import "../AdminStyle.css";
import "./ConditionsStyle.css";
import React from "react";
import * as Icon from "react-bootstrap-icons";

export default function ConditionInfoForm({
  name,
  setName,
  handleSubmit,
  working,
  props,
})
{

  return (
    <form onSubmit={handleSubmit} id="product-info-form">

      <div className="form-group">

        <label htmlFor="title">Pavadinimas</label>
        <input
          id="title"
          type="text"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder="pavadinimas"
          minLength="3"
          maxLength="100"
          className="form-control"
          required
        />

      </div>

      {
        <div id="btn-div">

          <button className="btn btn-secondary" type="button" onClick={props.history.goBack}>
            <Icon.ChevronLeft className="button-icon" />
            GRĮŽTI
          </button>

          <button className="btn btn-primary" disabled={working}>
            {working ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ""}
            <span className="font-weight-normal">PATVIRTINTI</span>
          </button>

        </div>
      }

    </form>
  );

}