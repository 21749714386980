import "../AdminStyle.css";
import "./ProductsStyle.css";
import React from "react";
import {Dropdown, DropdownButton} from 'react-bootstrap';

export default function ProductInfoForm({
  title,
  setTitle,
  description,
  setDescription,
  price,
  setPrice,
  discount,
  setDiscount,
  categoryId,
  setCategoryId,
  categories,
  conditionId,
  setConditionId,
  conditions,
})
{

  const categoryDict = {};
  const conditionDict = {};

  categories.forEach(element => {
    categoryDict[`${element[0]}`] = element[1];
  });

  conditions.forEach(element => {
    conditionDict[`${element[0]}`] = element[1];
  });

  return (

    <div className="form-group product-info-form">

      <div className="form-floating mb-3">
        <input
          id="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          placeholder="Pavadinimas"
          minLength="3"
          maxLength="100"
          className="form-control"
          required
        />
        <label htmlFor="title">Pavadinimas</label>
      </div>

      <div className="form-floating mb-3">
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          placeholder="Aprašymas"
          minLength="2"
          maxLength="1000"
          className="form-control"
          required
        />
        <label htmlFor="description">Aprašymas</label>
      </div>

      <div className="form-floating mb-3">
        <input
          id="price"
          type="number"
          pattern="[0-9]*"
          value={price}
          onChange={(e) => setPrice(e.currentTarget.value)}
          placeholder="Kaina €"
          maxLength="64"
          className="form-control"
          required
        />
        <label htmlFor="price">Kaina €</label>
      </div>

      <div className="form-floating mb-3">
        <input
          id="discount"
          type="number"
          pattern="[0-9]*"
          value={discount}
          onChange={(e) => setDiscount(e.currentTarget.value)}
          placeholder="Nuolaida €"
          maxLength="64"
          className="form-control"
        />
        <label htmlFor="discount">Nuolaida €</label>
      </div>

      <div className="dropdown-buttons">

        <div className="category-dropdown">
          <DropdownButton
            id="category-dropdown"
            title={`KATEGORIJA: ${categoryDict[categoryId]}`}
            onSelect={option => setCategoryId(option)}
          >
            {
              categories.map(element => (
                <Dropdown.Item key={element[0]} eventKey={element[0]}>
                  {element[1]}
                </Dropdown.Item>
              ))
            }
          </DropdownButton>
        </div>

        <div className="condition-dropdown">
          <DropdownButton
            id="condition-dropdown"
            title={`BŪKLĖ: ${conditionDict[conditionId]}`}
            onSelect={option => setConditionId(option)}
          >
            {
              conditions.map(element => (
                <Dropdown.Item key={element[0]} eventKey={element[0]}>
                  {element[1]}
                </Dropdown.Item>
              ))
            }
          </DropdownButton>
        </div>

      </div>

    </div>

  );

}