const ROUTES = {

    guestPage: () => "/",
    productsViewRoute: (id) => `/products/view/${id}`,
    productsListRoute: (category=0, page=1) => `/products/list/${category}/${page}`,
    productsSearchRoute: (keywords, page=1) => `/products/search/${keywords}/${page}`,

    adminLoginPageRoute: () => "/admin/login",
    adminDashboardRoute: () => "/admin",
    adminGetSignupURLRoute: () => "/admin/getSignupURL",
    adminProfileEditRoute: () => "/admin/profile/edit",
    adminProductsRoute: (category=0, page=1) => `/admin/products/list/${category}/${page}`,
    adminProductsPreviewRoute: (id) => `/admin/products/preview/${id}`,
    adminProductsModifyRoute: (id) => `/admin/products/modify/${id}`,
    adminProductsCreateRoute: () => "/admin/products/create",
    adminCategoriesRoute: () => "/admin/categories",
    adminCategoriesCreateRoute: () => "/admin/categories/create",
    adminCategoriesModifyRoute: (id) => `/admin/categories/modify/${id}`,
    adminConditionsRoute: () => "/admin/conditions",
    adminConditionsCreateRoute: () => "/admin/conditions/create",
    adminConditionsModifyRoute: (id) => `/admin/conditions/modify/${id}`,
    adminUsersRoute: () => `/admin/users`,
    adminPageInfoRoute: () => "/admin/pageInfo",
};

export default ROUTES;
