import React, { useState } from "react";
import { postForm } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";
import { validateToken } from "../../../utils/auth";
import SignupForm from "../../../components/admin/auth/SignupForm"
import ErrorPage from "../../../components/shared/error/ErrorPage"
import LoadingComponent from "../../../components/shared/loading/LoadingComponent"
import { useEffect } from "react";
import ROUTES from "../../../app/routes";

export default function SignupPage( props ) {
    const [email, setEmail] = useState("");
    const [emailRep, setEmailRep] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRep, setPasswordRep] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [tokenIsValid, setTokenIsValid] = useState(false);
    const [validationComplete, setValidationComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [emailAlert, setEmailAlert] = useState(false);
    const [passwordAlert, setPasswordAlert] = useState(false);


    const token = props.match.params.token;

    const goHome = () => {
        props.history.push("/");
    }

    useEffect(() => {
        validateToken(token, "signup")
        .then(isValid => {
            if (isValid) {
                setTokenIsValid(true);
                setValidationComplete(true);
            } else {
                setTokenIsValid(false);
                setValidationComplete(true);
            }
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setSuccessMessage("");
        setErrorMessage("");
        setEmailAlert(false);
        setPasswordAlert(false);

        var _credErrorMessage = "";
        if (email !== emailRep) {
            setEmailAlert(true);
            _credErrorMessage += "El. pašto adresai nesutampa. "
        }

        if (password !== passwordRep) {
            setPasswordAlert(true);
            _credErrorMessage += "Slaptažodžiai nesutampa."
        }

        if (_credErrorMessage !== "") {
            setErrorMessage(_credErrorMessage);
            return;
        }

        const formData = encodeFormData({
            email: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            jwt: token,
        });

        setIsLoading(true);
        postForm("user/signup.php", formData)
        .then(response => {
            setIsLoading(false);

            if (response.ok) {
                setSuccessMessage("Vartotojas sėkmingai sukurtas.");
                setEmail("");
                setEmailRep("");
                setPassword("");
                setPasswordRep("");
                setFirstname("");
                setLastname("");
                props.history.push(ROUTES.adminLoginPageRoute());
                return;
            }

            if (response.status === 400) {
                throw new Error("Puslapio klaida. Perkraukite ir bandykite dar kartą.");
            }

            if (response.status === 401) {
                throw new Error("Klaidinga nuoroda arba pasibaigęs galiojimo laikas.");
            }

            if (response.status === 409) {
                setEmailAlert(true);
                throw new Error("Vartotojas su tokiu el. pašto adresu jau egzistuoja.");
            }

            if (response.status === 500) {
                throw new Error("Serverio klaida. Bandykite vėliau dar kartą.");
            }

            throw new Error("Įvyko netikėta klaida: " + response.status);

        })
        .catch(error => {
            setErrorMessage(error.message);
            setIsLoading(false);
        })

    };

    return (validationComplete ?
        tokenIsValid ?
            <SignupForm
                handleSubmit={handleSubmit}
                firstname={firstname}
                setFirstname={setFirstname}
                lastname={lastname}
                setLastname={setLastname}
                email={email}
                setEmail={setEmail}
                emailRep={emailRep}
                setEmailRep={setEmailRep}
                password={password}
                setPassword={setPassword}
                passwordRep={passwordRep}
                setPasswordRep={setPasswordRep}
                errorMessage={errorMessage}
                successMessage={successMessage}
                isLoading={isLoading}
                emailAlert={emailAlert}
                passwordAlert={passwordAlert}
                goHome={goHome}
            /> :
            <ErrorPage
                errorMessage="Klaidinga nuoroda arba pasibaigęs galiojimo laikas. Kreipkitės į puslapio administratorių."
                goHome={goHome}
            />
        :
        <LoadingComponent />
    );

}