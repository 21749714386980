import React from "react";

import "./LoadingStyle.css";

export default function LoadingComponent () {
  return(
    <div className="container starter-template loading-component">

        <div className="d-flex justify-content-center loading-spinner-container">
          <div className="spinner-border text-primary loading-spinner" role="status" />
        </div>

        <div className="d-flex justify-content-center loading-title-container">
          <span className="loading-title">KRAUNAMA</span>
        </div>

    </div>
  );
}