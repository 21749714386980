import "../GuestStyle.css";
import "./ProductStyle.css";
import React from "react";
import _ from "lodash";

import ProductCardComponent from "./ProductCardComponent";
import NoProductsFoundComponent from "../../shared/error/NoProductsFoundComponent";

export default function ProductView ({
  props,
  products,
  loadComplete,
  openProduct,
  productCardRefs
}){

  return (
    <div className="content product-view">

      { loadComplete && _.isEmpty(products) ? <NoProductsFoundComponent /> : "" }

      {
        products.map((product, idx) => (
          <ProductCardComponent
            key={idx}
            props={props}
            product={product}
            openProduct={openProduct}
            productCardRefs={productCardRefs}
          />
        ))
      }
    </div>
  );
}