import "../GuestStyle.css";
import "./FooterStyle.css";
import React from "react";
import * as Icon from 'react-bootstrap-icons';

export default function FooterComponent( {pageData, footerRef, address} )
{
  return(
    <footer className="page-footer" id="footer" ref={footerRef}>

      <div className="footer-content container">
        <div className="footer-about footer-section">

          { pageData.page_description ?
            <>
              <h6>APIE MUS</h6>
              <p>{pageData.page_description}</p>
            </> : ""
          }

        </div>

        <div className="footer-contacts footer-section">

          { (address || pageData.email || pageData.phone_number) ?

            <>
              <h6>KONTAKTAI</h6>

              <dl>

                { address ?
                  <>
                    <dt><Icon.House /></dt>
                    <dd>{address}</dd>
                  </> : ""
                }

                { pageData.email ?
                  <>
                    <dt><Icon.Envelope /></dt>
                    <dd>{pageData.email}</dd>
                  </> : ""
                }

                { pageData.phone_number ?
                  <>
                  <dt><Icon.Telephone /></dt>
                  <dd onClick={e => window.open(`tel:${pageData.phone_number}`)}>{pageData.phone_number}</dd>
                  </> : ""
                }

              </dl>
            </> : ""
          }

        </div>
      </div>
    </footer>
  );
}