import "../GuestStyle.css";
import "./MapStyle.css";
import React from "react";

export default function MapComponent({props, pageData}) {

  const url = pageData.google_maps_link;

  if (!url) {
    return (<></>);
  }

  return(
    <section className="map container starter-template page-section" role="main">

      <div className="content">

        <h5>MUS RASITE</h5>

        <div className="map">

          <iframe
            src={url}
            allowfullscreen=""
            loading="lazy"
            title="map-iframe"
            id="map-iframe"
          >

          </iframe>

        </div>
      </div>

    </section>
  );
}