import React, { useEffect, useState, useRef } from "react";
import ImageGalleryComponent from "../../../components/shared/image/ImageGalleryComponent";

export default function ImageGallery({images, loadComplete}){

    const thumbsRef = useRef(null);
    const imageRefs = useRef([]);

    const [mainImage, setMainImage] = useState(images[0]);
    const [fullScreen, setFullScreen] = useState(false);
    const [keyPressed, setKeyPressed] = useState("");

    if (!mainImage && images[0]) {
        setMainImage(images[0]);
    }

    const changePhoto = (direction) => {
        if (images.length <= 1) return;

        const currentIndex = images.indexOf(mainImage);
        var newIndex = currentIndex + direction;

        if (newIndex >= images.length) newIndex = 0;
        if (newIndex < 0 ) newIndex = images.length -1;

        setMainImage(images[newIndex]);
        scrollToThumb(images[newIndex]);
    }

    useEffect(() => {
        imageRefs.current = imageRefs.current.slice(0, images.length);
    }, [images]);

    useEffect(() => {
        if (keyPressed === "ArrowLeft") {
            changePhoto(-1);
        } else if (keyPressed === "ArrowRight") {
            changePhoto(1);
        } else if (keyPressed === "Escape") {
            setFullScreen(false);
        }
    }, [keyPressed]);

    const upHandler = (key) => {
        setKeyPressed(key.key);
        setKeyPressed("");
    }

    useEffect(() => {
        window.addEventListener("keyup", upHandler);
        return () => {
          window.removeEventListener("keyup", upHandler);
        };
      }, []);

    const handleScroll = (direction) => {

        if (!thumbsRef) return;

        if (direction === "left") {
            return thumbsRef.current.scrollTo({
                left: thumbsRef.current.scrollLeft -= 100,
                behavior: "smooth"
            });
        } else {
            return thumbsRef.current.scrollTo({
                left: thumbsRef.current.scrollLeft += 100,
                behavior: "smooth"
            });
        }
    };

    const scrollToThumb = (image) => {
        if (!thumbsRef) return;
        const imageRef = imageRefs.current[images.indexOf(image)];
        imageRef.scrollIntoView({
            block: "end", inline: "center"
        });
    }

    return(
        <ImageGalleryComponent
            images={images}
            mainImage={mainImage}
            setMainImage={setMainImage}
            handleScroll={handleScroll}
            thumbsRef={thumbsRef}
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            loadComplete={loadComplete}
            changePhoto={changePhoto}
            imageRefs={imageRefs}
        />
    );
}