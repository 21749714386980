import React, { useEffect } from "react";

import { checkLogin } from "../../../utils/auth";
import PrivateRouteComponent from "../../../components/admin/auth/PrivateRouteComponent";

export default function PrivateRoute ( props ) {

    useEffect(() => (
        checkLogin(props.dispatch, props.auth)
    ), [props.path]);

    return (
        <PrivateRouteComponent {...props}/>
    );

}