import React, { useState, useEffect } from "react";
import ProductModifyComponent from "../../../components/admin/products/ProductModifyComponent";
import { get, postFormData } from "../../../utils/requests";
import { getFormData } from "../../../utils/form";

import ImageTools from "../../../utils/ImageTools";
import _ from "lodash";

export default function ProductModifyPage( props ) {
    const [productLoaded, setProductLoaded] = useState(false);
    const [categoriesLoaded, setCategoriesLoaded] = useState(false);
    const [product, setProduct] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [categoryId, setCategoryId] = useState(-1);
    const [categories, setCategories] = useState([]);
    const [id, setId] = useState();
    const [productError, setProductError] = useState();
    const [categoryError, setCategoryError] = useState();
    const [updateError, setUpdateError] = useState();
    const [working, setWorking] = useState(false);
    const [images, setImages] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [savedImages, setSavedImages] = useState([]);
    const [removeImages, setRemoveImages] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);
    const [conditionsLoaded, setConditionsLoaded] = useState(false);
    const [conditions, setConditions] = useState([]);
    const [conditionId, setConditionId] = useState(-1);
    const [settingImages, setSettingImages] = useState(false);
    const [imageError, setImageError] = useState("");
    const [mainImage, setMainImage] = useState("");

    useEffect(() => {
        setConditionsLoaded(false);

        get("/condition/read.php", {}, true)
        .then(response => {
            setConditionsLoaded(true);
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            const rows = [[-1, "Nenurodyta"]];
            data.payload.map(element => {
                rows.push([element.id, element.name]);
            });
            setConditions(rows);
        })
    }, []);

    useEffect(() => {
        setProductLoaded(false);
        setCategoriesLoaded(false);

        get("/product/read_one.php", {id: props.match.params.id}, true)
        .then(response => {

            setProductLoaded(true);

            const handled_requests = [200, 400, 404, 500];

            if (handled_requests.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unexpected response: ${response.status}`);
            }
        })
        .then(data => {

            const product_data = data.payload;

            if (data.code === 200) {
                setProduct(product_data);
                setTitle(product_data.title);
                setDescription(product_data.description);
                setPrice(product_data.price);
                setDiscount(product_data.discount);
                setCategoryId(-1);
                setConditionId(product_data.condition_id);
                setId(product_data.id);
                setSavedImages(product_data.images);
                setMainImage(product_data.images[0]);
                return product_data;
            } else if (data.code === 400) {
                setProductError("Klaidinga užklausa");
            } else if (data.code === 404) {
                setProductError("Produktas nerastas");
            } else if (data.code === 500) {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("500: Internal Server Error");
                }
            }
            else {
                throw new Error("Faulty response data");
            }

        }).then(product_data => {

            get("/category/read.php", {}, true)
            .then(response => {

                setCategoriesLoaded(true);

                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                const rows = [[-1, "Nenurodyta"]];
                data.payload.map(element => {
                    rows.push([element.id, element.name]);

                    if (product_data && product_data.category_id == element.id) {
                        setCategoryId(element.id);
                    }
                });
                setCategories(rows);
            })
            .catch(e => {
                setCategoryError("Netikėta klaida (kategorija): " + e.message);
            })
        })
        .catch(e => {
            setProductError("Netikėta klaida (produktas): " + e.message);
        })

    }, [dataChanged]);

    function getErrorMessage(message, info) {
        var errorMessage = message;

        if (info) {
            errorMessage += ` ${info}`;
        }

        return errorMessage;
    }

    function handleSubmit(e) {
        e.preventDefault();

        var _mainImage = "";
        const _removeImages = [];

        if (!_.isEmpty(mainImage)) {
            _mainImage = mainImage.large.split("=")[1].split("/")[1];
            _mainImage = _mainImage.split("_L.")[0].split(".")[0];
        }

        removeImages.forEach(url => {
            var _url = url.large.split("=")[1].replace("_L.", ".");
            _removeImages.push(_url);
        });


        var data = {
            id: id,
            title: title,
            description: description,
            price: price,
            discount: discount,
            category_id: categoryId,
            condition_id: conditionId,
            remove_images: JSON.stringify(_removeImages),
            main_image: _mainImage,
            jwt: props.auth.JWT
        }

        for (let i = 0; i < images.length; i++) {
            data[`image${i}`] = images[i];
        }

        setWorking(true);
        setUpdateError("");
        setSuccessMessage("");

        postFormData("product/update.php", getFormData(data))
        .then(response => {
            setWorking(false);

            const supported_responses = [200, 400, 401, 404, 500, 503];

            if (supported_responses.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unsupported response: ${response.status}`);
            }
        })
        .then(data => {

            if (data.code === 200) {
                setSuccessMessage("Duomenys atnaujinti sėkmingai");
                setRemoveImages([]);
                setImages([]);
                setMainImage("");
                setDataChanged(!dataChanged);
            } else if (data.code === 400) {
                setUpdateError(getErrorMessage("Klaidinga užklausa.", data.error));
            } else if (data.code === 401) {
                setUpdateError(getErrorMessage("Klaidingi prisijungimo duomenys.", data.error));
            } else if (data.code === 404) {
                setUpdateError("Produktas nerastas.");
            } else if ([500, 503].includes(data.code)) {
                setUpdateError(getErrorMessage("Serverio klaida.", data.error));
            } else {
                setUpdateError("Netikėta serverio klaida.");
            }
        })
        .catch(e => {
            setWorking(false);
            setUpdateError("Netikėta klaida: " + e.message);
        });
    }

    function checkRemoveImage(url, removeOnly=false) {
        if (removeImages.includes(url)) {
            setRemoveImages(
                removeImages.filter((_url) => {
                    return _url !== url;
                })
            );
        } else if (!removeOnly) {
            setRemoveImages([...removeImages, url]);
        }

        if (mainImage === url) {
            setMainImage("");
        }
    }

    function makeMainImage(url) {

        if (url === mainImage) {
            setMainImage("");
            return;
        }

        checkRemoveImage(url, true);
        setMainImage(url);
    }

    const resizeAndSetImages = async (uploadImages) => {
        setSettingImages(true);
        setImageError("");

        try {
            const imgTools = new ImageTools();
            const tempImages = [];

            for (let i = 0; i < uploadImages.length; i++) {
                let resized = await imgTools.resize(uploadImages[i], {height: 1600, width: 1600});
                tempImages.push(resized);
            };

            setImages([...tempImages]);
        } catch (error) {
            setImageError(`Nepavyko sumažinti nuotraukų. Patalpinimas gali užtrukti ilgiau: ${error.message}`);
            setImages(uploadImages);
        }

        setSettingImages(false);
    }

    return (
        <ProductModifyComponent
            product={product}
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            price={price}
            setPrice={setPrice}
            discount={discount}
            setDiscount={setDiscount}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            categories={categories}
            handleSubmit={handleSubmit}
            productLoaded={productLoaded}
            categoriesLoaded={categoriesLoaded}
            updateError={updateError}
            productError={productError}
            categoryError={categoryError}
            working={working}
            props={props}
            successMessage={successMessage}
            savedImages={savedImages}
            removeImages={removeImages}
            checkRemoveImage={checkRemoveImage}
            conditionId={conditionId}
            setConditionId={setConditionId}
            conditions={conditions}
            conditionsLoaded={conditionsLoaded}
            resizeAndSetImages={resizeAndSetImages}
            settingImages={settingImages}
            imageError={imageError}
            makeMainImage={makeMainImage}
            mainImage={mainImage}
        />
    );
}