import "../AdminStyle.css";
import "./HeaderStyle.css";
import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import _ from "lodash";

import ROUTES from "../../../app/routes";
import { logOut } from "../../../utils/auth";

export default function HeaderComponent({
  props,
  authProps,
  showUserOptions,
  setShowUserOptions,
}) {

  return (
    <header role="main" className="admin-header">

      <Navbar id="header-navbar" bg="light" expand="lg">
        <Container>

          <Navbar.Brand
            onClick={e => props.history.push(ROUTES.adminDashboardRoute())
          }>
            <div className="brand-container">
              <div className="brand-icon">
                <Icon.GearFill />
              </div>
              <div className="brand-text">
                <span className="text-upper">SVETAINĖS</span>
                <span className="text-lower">VALDYMAS</span>
              </div>
            </div>
          </Navbar.Brand>

          <div className="login-container">
            {
              authProps.auth.loginComplete ?
                (authProps.auth.loggedIn && !_.isEmpty(authProps.auth.userData)) ?

                  <div
                    className="user-details"
                    onClick={e => setShowUserOptions(!showUserOptions)}
                  >

                    <div className="user-name">
                      <span>
                        {
                          `${authProps.auth.userData.firstname} ` +
                          `${authProps.auth.userData.lastname}`
                        }
                      </span>
                    </div>

                    <div className="user-picture">
                      <Icon.PersonFill />
                    </div>

                    <div
                      className={`user-actions ${showUserOptions ? "" : "hidden"}`}
                    >

                        <Link to={ROUTES.adminProfileEditRoute} className="user-action-item">
                          <span>REDAGUOTI PASKYRĄ</span>
                        </Link>

                        <hr />

                        <span
                          className="user-action-item"
                          onClick={e => logOut(authProps.dispatch)}
                        >
                          ATSIJUNGTI
                        </span>

                    </div>

                  </div> :

                  <div className="login-text">
                    <Link to={ROUTES.adminLoginPageRoute()} >
                      <span>PRISIJUNGTI</span>
                    </Link>
                  </div> : ""
            }
          </div>

        </Container>
      </Navbar>

    </header>
  );
}