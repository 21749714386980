import "../AdminStyle.css";
import "./AuthStyle.css";
import React from "react";

export default function SignupForm ({
    handleSubmit,
    firstname,
    setFirstname,
    lastname,
    setLastname,
    email,
    setEmail,
    emailRep,
    setEmailRep,
    password,
    setPassword,
    passwordRep,
    setPasswordRep,
    errorMessage,
    successMessage,
    isLoading,
    emailAlert,
    passwordAlert,
    goHome,
  })
{
  return(
    <main role="main" className="container starter-template">

      <div className="row">
        <div className="col">

          <div id="response">
            {errorMessage ? <div className='alert alert-danger'>{errorMessage}</div> : ""}
            {successMessage ? <div className='alert alert-success'>{successMessage}</div> : ""}
          </div>

          <div id="content">

            <h2>REGISTRACIJA</h2>

            <form onSubmit={handleSubmit} id="reg_form">

              <div className="form-group">

                <div className="form-floating mb-3">
                  <input
                    id="reg_firstname"
                    type="text"
                    value={firstname}
                    onChange={(e) => setFirstname(e.currentTarget.value)}
                    placeholder="Vardas"
                    minLength="3"
                    maxLength="64"
                    className="form-control"
                    required
                  />
                  <label htmlFor="reg_firstname">Vardas</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    id="reg_lastname"
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastname(e.currentTarget.value)}
                    placeholder="Pavardė"
                    minLength="2"
                    maxLength="64"
                    className="form-control"
                    required
                  />
                  <label htmlFor="reg_lastname">Pavardė</label>
                </div>

              </div>

              <div className="form-group">

                <div className="form-floating mb-3">
                  <input
                    id="reg_email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    placeholder="El. pašto adresas"
                    maxLength="64"
                    className={emailAlert ? "form-control alert-danger" : "form-control"}
                    required
                  />
                  <label htmlFor="reg_email">El. pašto adresas</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    id="reg_rep_email"
                    type="email"
                    value={emailRep}
                    onChange={(e) => setEmailRep(e.currentTarget.value)}
                    placeholder="Pakartokite el. pašto adresą"
                    maxLength="64"
                    className={emailAlert ? "form-control alert-danger" : "form-control"}
                    required
                  />
                  <label htmlFor="reg_email">Pakartokite el. pašto adresą</label>
                </div>

              </div>

              <div className="form-group">

                <div className="form-floating mb-3">
                  <input
                    id="reg_password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    placeholder="Slaptažodis"
                    minLength="8"
                    className={passwordAlert ? "form-control alert-danger" : "form-control"}
                    required
                    />
                  <label htmlFor="password">Slaptažodis</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    id="reg_rep_password"
                    type="password"
                    value={passwordRep}
                    onChange={(e) => setPasswordRep(e.currentTarget.value)}
                    placeholder="Pakartokite slaptažodį"
                    minLength="8"
                    className={passwordAlert ? "form-control alert-danger" : "form-control"}
                    required
                  />
                  <label htmlFor="password">Pakartokite slaptažodį</label>
                </div>

              </div>

              {!successMessage ?
                <button className="btn btn-primary" disabled={isLoading}>
                  {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ""}
                  <span className="font-weight-normal">Registruotis</span>
                </button>
                : ""
              }

            </form>

            {successMessage ? <button className="btn btn-primary" onClick={goHome}>Eiti į pradinį puslapį</button> : "" }

          </div>
        </div>
      </div>
    </main>
  );
}