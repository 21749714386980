import "../GuestStyle.css";
import "./ProductStyle.css";
import React from "react";
import ContentLoader from "react-content-loader";
import _ from 'lodash';

export default function ProductCardComponent( {props, product, openProduct, productCardRefs} ) {

  return (
    <div
      id={product.id}
      className="product-card"
      onClick={e => openProduct(product.id)}
      ref={element => productCardRefs.current[product.id] = element}
    >

      <div className="card-image">
        { !_.isEmpty(product.images) ?
          <picture>
            <source srcset={product.images[0].medium} media="(min-width: 992px)" />
            <source srcset={product.images[0].large} media="(min-width: 768px)" />
            <img className="card-image" src={product.images[0].medium} alt="" />
          </picture> : ""
        }
      </div>

      {! _.isEmpty(product) ?
        <div className="product-card-content">
          <span className="title">{product.title}</span>
          <span className="price">{product.price}€</span>
        </div> :
        <div className="product-card-content">
          <ContentLoader className="content-loader">
            <rect x="25%" y="5" rx="4" ry="4" width="50%" height="15" />
            <rect x="42.5%" y="25" rx="4" ry="4" width="15%" height="20" />
          </ContentLoader>
        </div>
      }

    </div>
  );
}