import React, { useState } from "react";
import _ from "lodash";

import { postForm } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";
import { setJWT, setLoggedIn, setLoginComplete } from "../../../features/auth/authSlice";
import LoginForm from "../../../components/admin/auth/LoginForm"

export default function LoginPage( {history, dispatch, location} ) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        setErrorMessage("");

        if (email.length === 0 || password.length === 0) {
            setErrorMessage("Patikrinkite įvestus duomenis.");
            return;
        }

        setIsLoading(true);

        postForm("user/login.php", encodeFormData({email: email, password: password}))
        .then(response => {

            if (response.ok) {
                return response.json();
            } else if (response.status === 401) {
                throw new Error("Neteisingas el. pašto adresas arba slaptažodis.");
            } else if (response.status === 403) {
                throw new Error("Paskyra neturi tinkamų privilegijų. Susisiekite su svetainės administratoriumi.");
            } else if (response.status === 400) {
                throw new Error("Puslapio klaida. Perkraukite ir bandykite dar kartą.");
            } else if (response.status === 500) {
                throw new Error("Serverio klaida. Bandykite vėliau dar kartą.");
            }

            throw new Error("Įvyko netikėta klaida: " + response.status);

        })
        .then(data => {

            if (data.payload === undefined) {
                throw new Error("Missing token");
            }

            dispatch(setJWT(data.payload));
            dispatch(setLoginComplete(false));
            dispatch(setLoggedIn(false));
            setIsLoading(false);

            if (!_.isEmpty(location.state) && !_.isEmpty(location.state.from)){
                history.push(location.state.from);
            } else {
                history.push("/admin");
            }
        })
        .catch(error => {
            setErrorMessage(error.message);
            dispatch(setLoginComplete(false));
            dispatch(setLoggedIn(false));
            setIsLoading(false);
        })

    };

    return (
        <LoginForm
            handleSubmit={handleSubmit}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            errorMessage={errorMessage}
            isLoading={isLoading}
        />
    );

}