export function encodeFormData(form) {
    const encodedForm = [];

    for (var property in form) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(form[property]);
        encodedForm.push(encodedKey + "=" + encodedValue);
    }

    return encodedForm.join("&");
}

export function getFormData(form) {
    const formData = new FormData();

    for (var property in form) {
        formData.append(property, form[property]);
    }

    return formData;
}