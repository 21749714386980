import "../GuestStyle.css";
import "./ProductStyle.css";
import React from "react";
import * as Icon from "react-bootstrap-icons";

import PagingButtons from "../../shared/utils/PagingButtons";
import ListSelect from "../../shared/utils/ListSelect";
import ProductView from "./ProductView";
import { __ } from "../../../utils/lolodash";

export default function ProductsPageComponent({
    props,
    products,
    loadError,
    paging,
    currentPage,
    goTo,
    pages,
    openProduct,
    productCardRefs,
    showCategories,
    setShowCategories,
    categories,
    conditions,
    goToCategory,
    loadComplete,
    category,
    sorting,
    sortBy,
    setSortBy,
    showConditions,
    setShowConditions,
    showSorting,
    setShowSorting,
    condition,
    setCondition,
  })
{

  return (
    <section className="products-page container page-section">

      <div className="title-bar">

        <div className="title">
          {
            category === 0 ?
              <h5>VISOS PREKĖS</h5> :
              <h5>{__.findEntry(categories, category)}</h5>
          }
        </div>

        <div className="filters">

          <div className={`icon ${category !== 0 ? "icon-active" : ""}`}>
            <Icon.ListUl
              onClick={() => setShowCategories(!showCategories)}
            />
          </div>

          <div className={`icon ${condition !== 0 ? "icon-active" : ""}`}>
            <Icon.ClipboardCheck
              onClick={() => setShowConditions(!showConditions)}
            />
          </div>

          <div className={`icon ${sortBy !== 0 ? "icon-active" : ""}`}>
            <Icon.SortDown
              onClick={() => setShowSorting(!showSorting)}
            />
          </div>

        </div>

      </div>

      <div className="filtering-container">
        <ListSelect
          title="KATEGORIJOS"
          show={showCategories}
          options={categories}
          onSelect={goToCategory}
          currentItem={category}
        />

        <ListSelect
          title="BŪKLĖS"
          show={showConditions}
          options={conditions}
          onSelect={setCondition}
          currentItem={condition}
        />

        <ListSelect
          title="RIKIAVIMAS"
          show={showSorting}
          options={sorting}
          onSelect={setSortBy}
          currentItem={sortBy}
        />
      </div>

      <ProductView
        props={props}
        products={products}
        openProduct={openProduct}
        productCardRefs={productCardRefs}
        loadComplete={loadComplete}
      />

      <PagingButtons
        props={props}
        pages={pages}
        currentPage={currentPage}
        paging={paging}
        goTo={goTo}
      />

    </section>
  );
}