import "../AdminStyle.css";
import "./UsersStyle.css";
import React from "react";
import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import _ from "lodash";

import ROUTES from "../../../app/routes";
import NoProductsFoundComponent from "../../shared/error/NoProductsFoundComponent";
import LoadingComponent from "../../shared/loading/LoadingComponent";
import UserCard from "./UserCard";

export default function UsersPageComponent({
  props,
  users,
  loadComplete,
  removeUser,
  removeError,
  setAdmin,
  adminUpdateError,
  loadError,
  adminUpdateWorking,
}) {

  return (

    <div className="container container-admin">

      <div className="page-header">
        <h1>VARTOTOJAI</h1>

        <div id="response">
          {loadError ? <div className='alert alert-danger'>{loadError}</div> : ""}
          {removeError ? <div className='alert alert-danger'>{removeError}</div> : ""}
          {adminUpdateError ? <div className='alert alert-danger'>{adminUpdateError}</div> : ""}
        </div>

        <hr />

      </div>

      <div className="main-content">
        {
          loadComplete ?
            !_.isEmpty(users) ?
              <div className="content-items">
                {
                  users.map((user, idx) => (
                    <UserCard
                      index={idx}
                      user={user}
                      props={props}
                      onRemove={() => removeUser(user.id)}
                      setAdmin={setAdmin}
                      adminUpdateWorking={adminUpdateWorking}
                    />
                  ))
                }
              </div> :
              <NoProductsFoundComponent generic={true}/> :
            <LoadingComponent />
        }
      </div>

      <div div="btn-div" className="page-section">
        <Link to={ROUTES.adminDashboardRoute()} >
          <button className="btn btn-secondary m-r-1em">
            <Icon.ChevronLeft className="button-icon" />
            GRĮŽTI
          </button>
        </Link>
      </div>

      <footer className="admin-spacer" />

    </div>
  );

}
