import React from "react";
import { Button } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

import "./ImageGalleryStyle.css";

export default function ImageGalleryComponent({
  images,
  mainImage,
  setMainImage,
  handleScroll,
  thumbsRef,
  fullScreen,
  setFullScreen,
  loadComplete,
  changePhoto,
  imageRefs,
}){

  return(
    <div className="image-gallery">

      { fullScreen ?
        <div
          className={"full-screen-image-overlay"}
          onClick={(event) => {
            if (["full-screen-image-overlay", "full-screen-image"].includes(event.target.className)) {
              setFullScreen(false)
            }
          }}
        >
          <img className="full-screen-image" src={mainImage.large} alt="main" />

          <div className="change-photo-icon-full-screen prev-photo-icon">
            <Icon.ChevronLeft onClick={() => changePhoto(-1)} />
          </div>

          <div className="change-photo-icon-full-screen next-photo-icon">
            <Icon.ChevronRight onClick={() => changePhoto(1)}/>
          </div>

          <div className="contract-icon-container">
            <Icon.ArrowsAngleContract onClick={() => setFullScreen(false)}/>
          </div>

        </div> : ""
      }

      <div className="main-image-container">
        { loadComplete ? mainImage ?

          <div className="main-image-show">
            <img className="main-image" src={mainImage.large} alt="main"/>

            <div className="overlay-container">

              <div className="change-photo-icon prev-photo-icon">
                <Icon.ChevronLeft onClick={() => changePhoto(-1)}/>
              </div>

              <div className="change-photo-icon next-photo-icon">
                <Icon.ChevronRight onClick={() => changePhoto(1)}/>
              </div>

              <div className="expand-icon">
                <Icon.ArrowsAngleExpand onClick={() => setFullScreen(true)}/>
              </div>

            </div>

          </div> :

          <div className="no-image">
            <Icon.EmojiFrown />
            <h4>NUOTRAUKA NERASTA</h4>
          </div> : ""
        }
      </div>

      <div className="thumbnail-container">

        <Button onClick={() => handleScroll("left")}>
          <Icon.ChevronCompactLeft />
        </Button>

        <div className="thumbnails" ref={thumbsRef}>

          {
            images.map((url, idx) => (
              <div key={idx} className="img-spacer">
                <img
                  className={"thumbnail " + (url === mainImage ? "selected" : "")}
                  src={url.thumb}
                  key={idx}
                  alt={`thumbnail_${idx}`}
                  onClick={() => (setMainImage(url))}
                  ref={element => imageRefs.current[idx] = element}
                />
              </div>
            ))
          }

        </div>

        <Button onClick={() => handleScroll("Right")}>
          <Icon.ChevronCompactRight />
        </Button>
      </div>

    </div>
  );
}