import React from "react";
import * as Icon from "react-bootstrap-icons";

import "./ErrorStyle.css";

export default function NoProductsFoundComponent({ props, generic=false }) {
  return (
    <div className="no-products-container">
      <Icon.EmojiFrown />
      <h4>ATSIPRAŠOME</h4>
      <h4>NEPAVYKO RASTI JOKIŲ {generic ? "DUOMENŲ" : "PREKIŲ"}</h4>
    </div>
  );
}