import "../AdminStyle.css";
import "./CategoriesStyle.css";
import React from "react";

import CategoryInfoForm from "./CategoryInfoForm";
import LoadingComponent from "../../shared/loading/LoadingComponent";

export default function CategoryModifyComponent({
  category,
  name,
  setName,
  handleSubmit,
  categoryLoaded,
  updateError,
  categoryError,
  working,
  props,
})
{

  return (
    <div className="container container-admin">

      <div id="response">
        {categoryError ? <div className='alert alert-danger'>{categoryError}</div> : ""}
      </div>

      <div id="response">
        {updateError ? <div className='alert alert-danger'>{updateError}</div> : ""}
      </div>

      {
        (categoryLoaded) || categoryError ?
          categoryLoaded && category ?
            <>
              <div className="page-header">
                <h2>KATEGORIJOS REDAGAVIMAS</h2>
                <hr/>
              </div>

              <CategoryInfoForm
                name={name}
                setName={setName}
                handleSubmit={handleSubmit}
                categoryLoaded={categoryLoaded}
                working={working}
                props={props}
              />

              <footer className="admin-spacer" />
            </>:
              <div className="page-header">
                <h2>Kategorija Nerasta</h2>
                <button className="btn btn-primary" onClick={props.history.goBack}>GRĮŽTI ATGAL</button>
              </div> :
        <LoadingComponent />
      }

    </div>
  );

}