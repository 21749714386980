import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import ROUTES from "../../../app/routes";
import { get, remove } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";
import { pagingDataToPages } from "../../../utils/paging";
import { selectScrollHistory, setHistory } from "../../../features/history/scrollHistorySlice";
import { selectSortBy, setSortBy, selectProductSorting } from "../../../features/sorting/productSortingSlice";
import { selectProductCondition, selectProductConditions, setProductCondition, setProductConditions } from "../../../features/conditions/productConditionsSlice";
import { selectProductCategories, setProductCategories } from "../../../features/categories/productCategoriesSlice";
import ProductsComponent from "../../../components/admin/products/ProductsPageComponent";

export default function ProductsPage( props ) {

    const currentPage = parseInt(props.match.params.page ? props.match.params.page : 1);
    const category = parseInt(props.match.params.category ? props.match.params.category : 0)

    const [loadComplete, setLoadComplete] = useState(false);
    const [products, setProducts] = useState([]);
    const [removeError, setRemoveError] = useState("");
    const [refreshData, setRefreshData] = useState(false);
    const [paging, setPaging] = useState({});
    const [pages, setPages] = useState([1]);
    const [showCategories, setShowCategories] = useState(false);
    const [showConditions, setShowConditions] = useState(false);
    const [showSorting, setShowSorting] = useState(false);

    const productCardRefs = useRef({});

    const dispatch = useDispatch();
    const scrollHistory = useSelector(selectScrollHistory);
    const sorting = useSelector(selectProductSorting);
    const sortBy = useSelector(selectSortBy);
    const conditions = useSelector(selectProductConditions);
    const condition = useSelector(selectProductCondition);
    const categories = useSelector(selectProductCategories);

    useEffect(() => {

        setLoadComplete(false);
        setProducts([]);
        setPaging({});

        get("/product/read_paging.php", {
            page: currentPage,
            category: category,
            sort_by: sortBy,
            condition: condition,
        }, true)
        .then(response => {

            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            if (data) {
                setProducts(data.payload.records);
                setPaging(data.payload.paging);
            }
            setLoadComplete(true);
        })
        .catch(error => {
            setLoadComplete(true);
        });
    }, [refreshData, currentPage, category, sortBy, condition]);

    useEffect(() => {
        setPages(pagingDataToPages(paging));
    }, [paging, category]);

    useEffect(() => {
        get("/category/read.php", {}, true)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {

            if (data && data.payload) {
                dispatch(setProductCategories(data.payload));
            }
        })
    }, []);

    useEffect(() => {
        get("/condition/read.php", {}, true)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {

            if (data && data.payload) {
                dispatch(setProductConditions(data.payload));
            }
        })
    }, []);

    useEffect(() => {
        productCardRefs.current = {...productCardRefs.current};
    }, [products]);

    useEffect(() => {
        if (!showCategories) return;

        if (showConditions) setShowConditions(false);
        if (showSorting) setShowSorting(false);

    }, [showCategories]);

    useEffect(() => {
        if (!showConditions) return;

        if (showCategories) setShowCategories(false);
        if (showSorting) setShowSorting(false);

    }, [showConditions]);

    useEffect(() => {
        if (!showSorting) return;

        if (showCategories) setShowCategories(false);
        if (showConditions) setShowConditions(false);

    }, [showSorting]);

    useEffect(() => {
        if (showSorting) setShowSorting(false);
    }, [sortBy]);

    useEffect(() => {
        if (showConditions) {
            setShowConditions(false);
            props.history.push(ROUTES.adminProductsRoute(category, 1));
        }
    }, [condition]);

    useEffect(() => {

        const scrollRef = scrollHistory.history[props.location.key];

        if (scrollRef) {
            const relevantRef = productCardRefs.current[scrollRef];
            if (!relevantRef) return;
            relevantRef.scrollIntoView({block: "center", behavior: "auto"});
        } else {
            window.scrollTo(0,0);
        }

    }, [products])

    const removeProduct = (id) => {

        if (!window.confirm("Ar tikrai norite ištrinti ši produktą?")) {
            return;
        }

        setRemoveError("");

        const supported_responses = [400, 401, 404, 500, 503];

        remove("/product/delete.php", encodeFormData({id: id, jwt: props.auth.JWT}))
        .then(response => {

            if (response.status === 204) {
                setRefreshData(!refreshData);
                return;
            } else if (supported_responses.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unsupported response: ${response.status}`);
            }

        })
        .then(data => {

            if (!data) {
                return;
            } else if (data.code === 400) {
                setRemoveError("Klaidinga užklausa");
            } else if (data.code === 401) {
                var errorMessage = "Klaidingi prisijungimo duomenys.";

                if (data.error) {
                    errorMessage += ` ${data.error}`;
                }

                setRemoveError(errorMessage);
            }
            else if (data.code === 404) {
                setRemoveError(`Produktas kurio id yra ${id} nerastas duomenų bazėje.`);
            } else if ([500, 503].includes(data.code)) {
                var errorMessage = "Įvyko serverio klaida.";

                if (data.error) {
                    errorMessage += ` ${data.error}`;
                }

                setRemoveError(errorMessage);
            } else {
                setRemoveError("Įvyko netikėta klaida.");
            }
        })
        .catch(error => {
            setRemoveError(`Įvyko klaida: ${error}`);
        })

    }

    const goToPage = (page) => {
        props.history.push(ROUTES.adminProductsRoute(category, page));
    }

    const goToCategory = (id) => {
        setShowCategories(false);
        props.history.push(ROUTES.adminProductsRoute(id));
    }

    const openProduct = (id) => {
        dispatch(setHistory({key: props.location.key, id: id}));
        props.history.push(ROUTES.adminProductsPreviewRoute(id))
    }

    return (
        <ProductsComponent
            products={products}
            loadComplete={loadComplete}
            removeProduct={removeProduct}
            removeError={removeError}
            pages={pages}
            currentPage={currentPage}
            paging={paging}
            goToPage={goToPage}
            openProduct={openProduct}
            productCardRefs={productCardRefs}
            goToCategory={goToCategory}
            category={category}
            categories={categories}
            showCategories={showCategories}
            setShowCategories={setShowCategories}
            condition={condition}
            conditions={conditions}
            setCondition={condition => dispatch(setProductCondition(condition))}
            showConditions={showConditions}
            setShowConditions={setShowConditions}
            sorting={sorting}
            sortBy={sortBy}
            setSortBy={value => dispatch(setSortBy(value))}
            showSorting={showSorting}
            setShowSorting={setShowSorting}
        />
    );
}