import React, {useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";

import ROUTES from "../../../app/routes";
import { get } from "../../../utils/requests";
import { pagingDataToPages } from "../../../utils/paging";
import SearchProductsComponent from "../../../components/guest/product/SearchProductsComponent";
import { selectScrollHistory, setHistory } from "../../../features/history/scrollHistorySlice";

export default function SearchProductPage ( {props, pageData } ) {

    const keywords = props.match.params.keywords;
    const currentPage = parseInt(props.match.params.page ? props.match.params.page : 1);

    const [loadComplete, setLoadComplete] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [products, setProducts] = useState([]);
    const [paging, setPaging] = useState({});
    const [pages, setPages] = useState([1]);
    const [searchText, setSearchText] = useState(keywords);

    const productCardRefs = useRef({});

    const dispatch = useDispatch();
    const scrollHistory = useSelector(selectScrollHistory);

    useEffect(() => {
        productCardRefs.current = {...productCardRefs.current};
    }, [products]);

    useEffect(() => {

        const scrollRef = scrollHistory.history[props.location.key];

        if (scrollRef) {
            const relevantRef = productCardRefs.current[scrollRef];
            if (!relevantRef) return;
            relevantRef.scrollIntoView({block: "center", behavior: "auto"});
        } else {
            window.scrollTo(0,0);
        }

    }, [products])

    useEffect(() => {
        setSearchText(keywords);

        setLoadComplete(false);
        setProducts([...Array(pageData.records_per_page)].map(e => ({})));

        get("/product/search_paging.php", {keywords: keywords, page: currentPage}, true)
        .then(response => {

            setLoadComplete(true);

            if (response.ok) {
                return response.json();
            } else if (response.status === 404) {
                setProducts([]);
                setPaging({});
            }
        })
        .then(data => {
            if (data) {
                setProducts(data.payload.records);
                setPaging(data.payload.paging);
            }
        })

    }, [currentPage, keywords]);

    useEffect(() => {
        setPages(pagingDataToPages(paging));
    }, [paging]);

    const goTo = (page) => {
        props.history.push(ROUTES.productsSearchRoute(keywords, page));
    }

    const openProduct = (id) => {
        dispatch(setHistory({key: props.location.key, id: id}));
        props.history.push(ROUTES.productsViewRoute(id));
    }

    return (
        <SearchProductsComponent
            props={props}
            pageData={pageData}
            products={products}
            loadComplete={loadComplete}
            setRefreshData={setRefreshData}
            paging={paging}
            currentPage={currentPage}
            refreshData={refreshData}
            goTo={goTo}
            pages={pages}
            searchText={searchText}
            setSearchText={setSearchText}
            productCardRefs={productCardRefs}
            openProduct={openProduct}
        />
    );
}