import "../AdminStyle.css";
import "./ProductsStyle.css";
import React from "react";
import { FormControl, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import ProductInfoForm from "./ProductInfoForm";
import LoadingComponent from "../../shared/loading/LoadingComponent";

export default function ProductModifyComponent({
  product,
  title,
  setTitle,
  description,
  setDescription,
  price,
  setPrice,
  discount,
  setDiscount,
  categoryId,
  setCategoryId,
  categories,
  handleSubmit,
  productLoaded,
  categoriesLoaded,
  updateError,
  productError,
  categoryError,
  working,
  props,
  savedImages,
  removeImages,
  successMessage,
  checkRemoveImage,
  conditionId,
  setConditionId,
  conditions,
  conditionsLoaded,
  resizeAndSetImages,
  settingImages,
  imageError,
  mainImage,
  makeMainImage,
})
{

  return (
    <div className="container container-admin">

      <div id="response">
        {successMessage ? <div className='alert alert-success'>{successMessage}</div> : ""}
      </div>

      <div id="response">
        {productError ? <div className='alert alert-danger'>{productError}</div> : ""}
      </div>

      <div id="response">
        {categoryError ? <div className='alert alert-danger'>{categoryError}</div> : ""}
      </div>

      <div id="response">
        {updateError ? <div className='alert alert-danger'>{updateError}</div> : ""}
      </div>

      <div id="response">
        {imageError ? <div className='alert alert-danger'>{imageError}</div> : ""}
      </div>

      {
        (productLoaded && categoriesLoaded && conditionsLoaded) || productError ?
          productLoaded && product ?
            <div className="product-modify-container">
              <div className="page-header">
                <h2>REDAGAVIMAS</h2>
                <hr/>
              </div>

              <form onSubmit={handleSubmit} id="product-info-form">

                <div className="modify-section">

                  <h5>DUOMENYS</h5>

                  <ProductInfoForm
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    price={price}
                    setPrice={setPrice}
                    discount={discount}
                    setDiscount={setDiscount}
                    categoryId={categoryId}
                    setCategoryId={setCategoryId}
                    categories={categories}
                    conditionId={conditionId}
                    setConditionId={setConditionId}
                    conditions={conditions}
                  />

                  <hr/>

                </div>

                <div className="modify-section">

                  <h5>NUOTRAUKOS</h5>


                  <div className="modify-sub-section">
                    <h6>ĮKELTI NAUJAS NUOTRAUKAS:</h6>

                    <FormControl
                      id="imageForm"
                      type="file"
                      label="File"
                      multiple
                      onChange={e => resizeAndSetImages(e.target.files)}
                    />
                  </div>

                  <div className="modify-sub-section">

                  <h6 className="roduct-modify">REDAGUOTI NUOTRAUKAS:</h6>

                    <div className="product-modify-photos">
                    {
                      savedImages.map((url, idx) => (
                        <div key={idx} className="photo-container">

                          <div className="single-photo">
                            <img
                              className={
                                (removeImages.includes(url) ? "remove-selected" : "") +
                                (mainImage === url ? " main-image" : "")
                              }
                              src={url.medium}
                              alt={`thumbnail_${idx}`}
                            />
                          </div>

                          <div className="photo-modify-buttons">
                            <Button
                              className="btn btn-danger"
                              onClick={e => checkRemoveImage(url)}
                            >
                              <span>IŠTRINTI</span>
                            </Button>

                            <Button
                              className="btn btn-success"
                              onClick={e => makeMainImage(url)}
                            >
                              <span>PADARYTI PAGRINDINE</span>
                            </Button>
                          </div>

                        </div>
                      ))
                    }
                    </div>
                  </div>

                  <hr />

                </div>

                <div id="btn-div">
                  <button className="btn btn-secondary" onClick={props.history.goBack} type="button">
                    <Icon.ChevronLeft className="button-icon" />
                    GRĮŽTI
                  </button>

                  <button className="btn btn-primary" disabled={working || settingImages}>
                    {(working || settingImages) ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ""}
                    <span className="font-weight-normal" >PATVIRTINTI</span>
                  </button>
                </div>

              </form>
            </div> :
              <div className="page-header">
                <h2>Produktas Nerastas</h2>
                <button className="btn btn-primary" onClick={props.history.goBack}>Grįžt atgal</button>
              </div> :
        <LoadingComponent />
      }

      <footer className="admin-spacer" />
    </div>
  );

}