import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../features/auth/authSlice";
import scrollHistorySlice from "../features/history/scrollHistorySlice";
import productSortingSlice from "../features/sorting/productSortingSlice";
import productConditionsSlice from "../features/conditions/productConditionsSlice";
import productCategoriesSlice from "../features/categories/productCategoriesSlice";

export default configureStore({
  reducer: {
    auth: authSlice,
    scrollHistory: scrollHistorySlice,
    productSorting: productSortingSlice,
    productConditions: productConditionsSlice,
    productCategories: productCategoriesSlice,
  },
});