import "../AdminStyle.css";
import "./DashboardStyle.css";
import React from "react";
import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';

import ROUTES from "../../../app/routes";

export default function GetSignupURLComponent({
  signupURL,
  loadComplete,
  handleCopy,
  loadError,
}){

  return(
    <main role="main" className="container starter-template">

      <div className="row">
        <div className="col">

          <div id="content">

            <h2>REGISTRACIJOS NUORODA</h2>
            <p>Ši nuoroda suteikia galimybę sukurti naują paskyrą.</p>

            <div id="response">
              {loadError ? <div className='alert alert-danger'>{loadError}</div> : ""}
            </div>

            <div className="code-block-card">

                <div className="card">
                    <div className="card-body">
                        {
                            !loadComplete ?
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> :
                            <code id="signup-url-block">{signupURL}</code>
                        }

                    </div>
                </div>

                <button type="button" className="btn btn-primary" onClick={handleCopy}>
                    <Icon.Clipboard />
                </button>
            </div>

            <div id="btn-div" className="page-section">
              <Link to={ROUTES.adminDashboardRoute()} >
                <button className="btn btn-secondary m-r-1em">
                  <Icon.ChevronLeft className="button-icon" />
                  GRĮŽTI
                </button>
              </Link>
            </div>


          </div>
        </div>
      </div>
    </main>
  );
}