import "../AdminStyle.css";
import "./CategoriesStyle.css";
import React from "react";
import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import _ from "lodash";

import ROUTES from "../../../app/routes";
import LoadingComponent from "../../shared/loading/LoadingComponent";
import NoProductsFoundComponent from "../../shared/error/NoProductsFoundComponent";
import DataCard from "../generic/DataCard";

export default function CategoriesPageComponent({
  props,
  categories,
  loadComplete,
  removeCategory,
  removeError
}) {

  return (

    <div className="container container-admin">

      <div className="page-header">

        <div className="header-title-bar">

          <div className="title">
            <h1>KATEGORIJOS</h1>
          </div>

          <div className="buttons">
            <Link to={ROUTES.adminCategoriesCreateRoute()} >
              <button className="btn btn-success m-r-1em">
                <Icon.Plus className="button-icon" />
              </button>
            </Link>
          </div>

        </div>

        <div id="response">
            {removeError ? <div className='alert alert-danger'>{removeError}</div> : ""}
        </div>

        <hr />

      </div>

      <div className="main-content">
        {
          loadComplete ?
            !_.isEmpty(categories) ?
              <div className="content-items">
                {
                  categories.map(category => (
                    <DataCard
                      data={category}
                      onEdit={ () =>
                        props.history.push(
                          ROUTES.adminCategoriesModifyRoute(category.id)
                        )
                      }
                      onRemove={  () => removeCategory(category.id) }
                    />
                  ))
                }
              </div> :
              <NoProductsFoundComponent generic={true}/> :
            <LoadingComponent />
          }
      </div>

      <div id="btn-div" className="page-section">
        <Link to={ROUTES.adminDashboardRoute()} >
          <button className="btn btn-secondary m-r-1em">
            <Icon.ChevronLeft className="button-icon" />
            GRĮŽTI
          </button>
        </Link>
      </div>

      <footer className="admin-spacer" />

    </div>
  );

}