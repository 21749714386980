import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import AppAdmin from "./AppAdmin";
import AppGuest from "./AppGuest";
import NotFoundPage from "../components/shared/error/NotFoundPage";

export default function App() {

  return (
    <Router>
      <Switch>
          <Route path="/admin/" component={(props) => <AppAdmin props={props} />} />
          <Route path="/" component={(props) => <AppGuest props={props} />} />
          <Route component={() => <NotFoundPage />} />
      </Switch>
    </Router>
  );
}