import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../app/routes";
import { get } from "../../../utils/requests.js";
import { selectScrollHistory, setHistory } from "../../../features/history/scrollHistorySlice";
import LatestProductsComponent from "../../../components/guest/product/LatestProductsComponent";

export default function LatestProducts( {props} ) {

    const [products, setProducts] = useState([]);
    const [loadComplete, setLoadComplete] = useState(false);
    const [refreshData, setRefreshData] = useState(false);

    const productCardRefs = useRef({});

    const dispatch = useDispatch();
    const scrollHistory = useSelector(selectScrollHistory);

    useEffect(() => {
        productCardRefs.current = {...productCardRefs.current};
    }, [products]);

    useEffect(() => {

        const scrollRef = scrollHistory.history[props.location.key];

        if (scrollRef) {
            const relevantRef = productCardRefs.current[scrollRef];
            if (!relevantRef) return;
            relevantRef.scrollIntoView({block: "center", behavior: "auto"});
        } else {
            window.scrollTo(0,0);
        }

    }, [products])

    useEffect(() => {
        setLoadComplete(false);

        get("/product/read.php", {limit: 8}, true)
        .then(response => {

            setLoadComplete(true);

            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            if (data) {
                setProducts(data.payload.records);
            }
        })
    }, [refreshData]);

    const openProduct = (id) => {
        dispatch(setHistory({key: props.location.key, id: id}));
        props.history.push(ROUTES.productsViewRoute(id));
    }

    return (
        <LatestProductsComponent
            products={products}
            props={props}
            loadComplete={loadComplete}
            productCardRefs={productCardRefs}
            openProduct={openProduct}
        />
    );
}