import React, { useState, useEffect } from "react";
import ProductCreateComponent from "../../../components/admin/products/ProductCreateComponent";
import { get, postFormData } from "../../../utils/requests";
import { getFormData } from "../../../utils/form";

import ImageTools from "../../../utils/ImageTools";

export default function ProductCreatePage( props ) {
    const [loadComplete, setLoadComplete] = useState(false);
    const [conditionLoadComplete, setConditionLoadComplete] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [categoryId, setCategoryId] = useState(-1);
    const [categories, setCategories] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [conditionId, setConditionId] = useState(-1);
    const [errorMessage, setErrorMessage] = useState();
    const [working, setWorking] = useState(false);
    const [images, setImages] = useState([]);
    const [settingImages, setSettingImages] = useState(false);
    const [imageError, setImageError] = useState("");

    useEffect(() => {
        setLoadComplete(false);

        get("/category/read.php", {}, true)
        .then(response => {
            setLoadComplete(true);
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            const rows = [[-1, "Nenurodyta"]];
            data.payload.map(element => {
                rows.push([element.id, element.name]);
            });
            setCategories(rows);
        })
    }, []);

    useEffect(() => {
        setConditionLoadComplete(false);

        get("/condition/read.php", {}, true)
        .then(response => {
            setConditionLoadComplete(true);
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            const rows = [[-1, "Nenurodyta"]];
            data.payload.map(element => {
                rows.push([element.id, element.name]);
            });
            setConditions(rows);
        })
    }, []);

    function handleSubmit(e) {
        e.preventDefault();

        var data = {
            title: title,
            description: description,
            price: price,
            discount: discount ? discount : 0,
            category_id: categoryId,
            condition_id: conditionId,
            jwt: props.auth.JWT,
        }

        for (let i = 0; i < images.length; i++) {
            data[`image${i}`] = images[i];
        }

        setErrorMessage("");
        setWorking(true);

        postFormData("product/create.php", getFormData(data))
        .then(response => {
            setWorking(false);

            var accepted_response_codes = [201, 400, 401, 500, 503];

            if (accepted_response_codes.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unexpected response code: ${response.status}`);
            }
        })
        .then(data => {

            if (data.code === 201) {
                props.history.goBack();
            } else if (data.code === 400) {
                setErrorMessage("Klaidinga užklausa. Patikrinkite įvestus duomenis.");
            } else if (data.code === 401) {
                setErrorMessage("Klaidingi prisijungimo duomenys.");
            } else if (data.code === 500) {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Serverio klaida.");
                }
            } else if (data.code === 503) {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Nepavyko sukurti produkto.");
                }
            } else {
                throw new Error("Faulty response data");
            }
        })
        .catch(e => {
            setErrorMessage("Netikėta klaida: " + e.message);
        });
    }

    const resizeAndSetImages = async (uploadImages) => {
        setSettingImages(true);
        setImageError("");

        try {
            const imgTools = new ImageTools();
            const tempImages = [];

            for (let i = 0; i < uploadImages.length; i++) {
                let resized = await imgTools.resize(uploadImages[i], {height: 1600, width: 1600});
                tempImages.push(resized);
            };

            setImages([...tempImages]);
        } catch (error) {
            setImageError(`Nepavyko sumažinti nuotraukų. Patalpinimas gali užtrukti ilgiau: ${error.message}`);
            setImages(uploadImages);
        }

        setSettingImages(false);
    }

    return (
        <ProductCreateComponent
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            price={price}
            setPrice={setPrice}
            discount={discount}
            setDiscount={setDiscount}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            categories={categories}
            handleSubmit={handleSubmit}
            loadComplete={loadComplete}
            setLoadComplete={setLoadComplete}
            errorMessage={errorMessage}
            working={working}
            props={props}
            resizeAndSetImages={resizeAndSetImages}
            conditionLoadComplete={conditionLoadComplete}
            conditions={conditions}
            conditionId={conditionId}
            setConditionId={setConditionId}
            settingImages={settingImages}
            imageError={imageError}
        />
    );
}