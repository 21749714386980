import "../GuestStyle.css";
import "./ProductStyle.css";

import React from "react";
import { Button } from "react-bootstrap";

import ROUTES from "../../../app/routes";
import ProductCardComponent from "./ProductCardComponent";

export default function LatestProductsComponent( {props, products, loadComplete, productCardRefs, openProduct} ) {

  var usableProducts = [];

  if (loadComplete) {
    usableProducts = products;
  } else {
    usableProducts = [{}, {}, {}, {}, {}, {}, {}, {}];
  }

  return (
    <section className="latest-products container page-section" role="main">
      <h5>NAUJAUSIOS PREKĖS</h5>

      <div className="main-content">
        {
          usableProducts.map((product, idx) => (
            <ProductCardComponent
              key={idx}
              props={props}
              product={product}
              openProduct={openProduct}
              productCardRefs={productCardRefs}
            />
          ))
        }
      </div>

      <div className="button-div">
        <Button onClick={e => props.history.push(ROUTES.productsListRoute())}>VISOS PREKĖS</Button>
      </div>

    </section>

  );
}