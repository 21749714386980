import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import { get } from "../utils/requests";
import HomePage from "../containers/guest/home/HomePage";
import Header from "../containers/guest/header/Header";
import Footer from "../containers/guest/footer/Footer";
import ProductPreviewPage from "../containers/guest/product/ProductPreviewPage";
import FullScreenLoadingPage from "../components/shared/loading/FullScreenLoadingPage";
import ProductsPage from "../containers/guest/product/ProductsPage";
import SearchProductPage from "../containers/guest/product/SearchProductPage";
import NotFoundPage from "../components/shared/error/NotFoundPage";

export default function AppGuest() {
  const [pageData, setPageData] = useState({});
  const [loadComplete, setLoadComplete] = useState(false);
  const [loadError, setLoadError] = useState("");
  const footerRef = useRef(null);
  const headerRef = useRef(null);

  const goToFooter = (instant=false) => {
    if (!footerRef) return;
    footerRef.current.scrollIntoView({ behavior: (instant ? "auto" : "smooth") });
  };

  const goToHeader = (instant=false) => {
    if (!headerRef) return;
    headerRef.current.scrollIntoView({ behavior: (instant ? "auto" : "smooth") });
  }

  useEffect(() => {

    const handled_responses = [200, 400, 404, 500];

    get("/pageInfo/read.php", {}, true)
    .then(response => {

      setLoadComplete(true);

      if (handled_responses.includes(response.status)) {
        return response.json();
      } else {
        throw new Error(`Unexpected response ${response.status}`);
      };

    })
    .then(data => {

      if (data.code === 200) {
        setPageData(data.payload);
      } else if (data.code === 400) {
        setLoadError("Klaidinga užklausa");
      } else if (data.code === 404) {
        setLoadError("Duomenys nerasti");
      } else if (data.code === 500) {
        var msg = "Serverio klaida."

        if (data.error) {
          msg += ` ${data.error}`
        }

        setLoadError(msg);
      } else {
        throw new Error("Faulty response data.");
      };

    })
    .catch(error => {
      setLoadError(`Netikėta klaida: ${error.message}`);
    })

  }, []);

  return (
    <Router>
      <>
      {!loadComplete ? <FullScreenLoadingPage /> : <FullScreenLoadingPage hide={true}/>}
      {loadComplete ?
        <>
          <Route path="/" component={(props) => <Header props={props} goToFooter={goToFooter} headerRef={headerRef}/>} />

          <Switch>
              <Route exact path="/" component={ (props) => <HomePage props={props} pageData={pageData} />} />
              <Route exact path="/products/list/:category/:page" component={(props) => <ProductsPage props={props} pageData={pageData} />} />
              <Route exact path="/products/view/:id" component={(props) => <ProductPreviewPage props={props} />} />
              <Route exact path="/products/search/:keywords/:page" component={(props) => <SearchProductPage props={props} pageData={pageData} />} />
              <Route component={() => <NotFoundPage />} />
          </Switch>

          <Footer footerRef={footerRef} pageData={pageData}/>
        </>:
        ""
      }
      </>
    </Router>
  );
}