import "../GuestStyle.css";
import "./ProductStyle.css";
import React from "react";
import { Form, FormControl, Button } from "react-bootstrap";

import ROUTES from "../../../app/routes";
import ProductView from "./ProductView";
import PagingButtons from "../../shared/utils/PagingButtons";

export default function SearchProductsComponent({
    props,
    products,
    loadError,
    paging,
    currentPage,
    goTo,
    pages,
    loadComplete,
    searchText,
    setSearchText,
    productCardRefs,
    openProduct
  })
{

  return (
    <section className="products-page container page-section">

      <h5>PAIEŠKA</h5>

      <Form
        className="d-flex"
        onSubmit={e => {
          e.preventDefault();
          if (!searchText) return;
          props.history.push(ROUTES.productsSearchRoute(searchText, 1));
        }}
      >
        <FormControl
          type="search"
          placeholder="Ieškoti"
          className="mr-2"
          aria-label="Ieškoti"
          value={searchText}
          onChange={e => setSearchText(e.currentTarget.value)}
        />

        <Button
          className={!searchText ? "disabled" : ""}
          type="submit"
          variant="outline-success"
        >
          Ieškoti
        </Button>

      </Form>

      <ProductView
        props={props}
        products={products}
        loadComplete={loadComplete}
        productCardRefs={productCardRefs}
        openProduct={openProduct}
      />

      <PagingButtons
        props={props}
        pages={pages}
        currentPage={currentPage}
        paging={paging}
        goTo={goTo}
      />

    </section>
  );
}