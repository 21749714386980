import React from "react";
import * as Icon from 'react-bootstrap-icons';

import "./UtilsStyle.css";

export default function PagingButtons ( {props, pages, currentPage, paging, goTo} ) {

  if (pages.length < 2) {
    return "";
  }

  return (
    <div className="pagination-div">
      <nav>
        <ul className="pagination">

          <li
            className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
          >
            <button
              className="page-link"
              onClick={e => goTo(currentPage - 1)}
            >
              <Icon.ChevronLeft />
            </button>
          </li>

          {
            pages.map((page, idx) => (
              <li
                key={idx}
                className={
                  `page-item
                  ${page === currentPage ? "active" : ""}
                  ${page === "..." ? "disabled" : ""}`
                }
              >
                <button
                  key={idx}
                  className="page-link"

                  onClick={e => goTo(page)}
                >
                  {page}
                </button>
              </li>
            ))
          }

          <li
            className={
              `page-item
              ${currentPage === paging.number_of_pages || !paging.number_of_pages ? "disabled" : ""}`
            }
          >
            <button
              className="page-link"
              onClick={e => goTo(currentPage + 1)}
            >
              <Icon.ChevronRight />
            </button>
          </li>

        </ul>
      </nav>
    </div>
  );
}