import "../AdminStyle.css";
import "./ConditionsStyle.css";
import React from "react";
import { Link } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import _ from "lodash";

import ROUTES from "../../../app/routes";
import LoadingComponent from "../../shared/loading/LoadingComponent";
import NoProductsFoundComponent from "../../shared/error/NoProductsFoundComponent";
import DataCard from "../generic/DataCard";

export default function ConditionsPageComponent( {props, conditions, loadComplete, removeCondition, removeError} )
{

  return (

    <div className="container container-admin">

      <div className="page-header">

        <div className="header-title-bar">

          <div className="title">
            <h1>BŪKLĖS</h1>
          </div>

          <div className="buttons">
            <Link to={ROUTES.adminConditionsCreateRoute()} >
              <button className="btn btn-success m-r-1em">
                <Icon.Plus className="button-icon" />
              </button>
            </Link>
          </div>

        </div>

        <div id="response">
          {removeError ? <div className='alert alert-danger'>{removeError}</div> : ""}
        </div>

        <hr />

      </div>

      <div className="main-content">
        {
          loadComplete ?
            !_.isEmpty(conditions) ?
              <div className="content-items">
                {
                  conditions.map(condition => (
                    <DataCard
                      data={condition}
                      onEdit={ () =>
                        props.history.push(
                          ROUTES.adminConditionsModifyRoute(condition.id)
                        )
                      }
                      onRemove={  () => removeCondition(condition.id) }
                    />
                  ))
                }
              </div> :
              <NoProductsFoundComponent generic={true}/> :
            <LoadingComponent />
        }

      </div>

      <div id="btn-div" className="page-section">
        <Link to={ROUTES.adminDashboardRoute()} >
          <button className="btn btn-secondary m-r-1em">
            <span><Icon.ChevronLeft className="button-icon" /></span>
            <span>GRĮŽTI</span>
          </button>
        </Link>
      </div>

      <footer className="admin-spacer" />

    </div>
  );

}