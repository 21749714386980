import React, { useState } from "react";
import HeaderComponent from "../../../components/guest/header/HeaderComponent";

export default function Header( {props, goToFooter, headerRef} ) {
    const [searchText, setSearchText] = useState("");

    const goTo = (path) => {
        props.history.push(path);
    }

    return (
        <HeaderComponent
            props={props}
            goTo={goTo}
            searchText={searchText}
            setSearchText={setSearchText}
            goToFooter={goToFooter}
            headerRef={headerRef}
        />
    );
}