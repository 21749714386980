import { createSlice } from "@reduxjs/toolkit";

export const scrollHistory = createSlice({
    name: "scrollHistory",
    initialState: {
        scrollHistory: {
            history: {},
        },
    },
    reducers: {
        setHistory: (state, action) => {

            const key = action.payload.key;
            const id = action.payload.id;

            const newEntry = {};
            newEntry[key] = id;

            state.scrollHistory["history"] = {
                ...state.scrollHistory["history"],
                ...newEntry,
            };
        },
    }
});

export const selectScrollHistory = (state) => state.scrollHistory.scrollHistory;
export default scrollHistory.reducer;
export const { setHistory } = scrollHistory.actions;