import React, { useState, useEffect } from "react";
import GetSignupURLComponent from "../../../components/admin/dashboard/GetSignupURLComponent";
import { postForm } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";

export default function GetSignupURLPage( props ) {
    const [signupURL, setSignupURL] = useState("");
    const [loadComplete, setLoadComplete] = useState(false);
    const [loadError, setLoadError] = useState("");

    useEffect(() => {
        postForm("user/get_signup_url.php", encodeFormData({jwt: props.auth.JWT}))
        .then( (response) => {
            setLoadComplete(true);
            setLoadError("");

            if (response.ok) {
                try {
                    return response.json();
                } catch {
                    throw new Error("Užklausos atsako duomenys yra nenumatyti");
                }
            }
        })
        .then( (data) => {
            setSignupURL(data.payload.ui_signup_url);
        })
        .catch(error => {
            setLoadError(`Neikėta klaida: ${error.message}`);
        });
    }, []);

    return (
        <GetSignupURLComponent
            signupURL={signupURL}
            loadComplete={loadComplete}
            handleCopy={copyURLToClipboard}
            loadError={loadError}
        />
    );
}

function copyURLToClipboard() {
    const text = document.getElementById("signup-url-block").innerText;
    navigator.clipboard.writeText(text);
}