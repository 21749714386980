import React, { useState, useEffect } from "react";
import UsersPageComponent from "../../../components/admin/users/UsersPageComponent";
import { get, remove, putForm } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";

export default function UsersPage( props ) {
    const [loadComplete, setLoadComplete] = useState(false);
    const [users, setUsers] = useState([]);
    const [removeError, setRemoveError] = useState("");
    const [refreshData, setRefreshData] = useState(false);
    const [adminUpdateError, setAdminUpdateError] = useState("");
    const [loadError, setLoadError] = useState("");
    const [adminUpdateWorking, setAdminUpdateWorking] = useState([]);

    useEffect(() => {
        setLoadComplete(false);

        get("/user/read.php", {jwt: props.auth.JWT})
        .then(response => {

            const handled_responses = [200, 400, 401, 404, 500];

            if (handled_responses.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unrecognized response code ${response.status}`);
            }
        })
        .then(data => {

            if (data.code === 200) {
                setUsers(data.payload);
                setLoadComplete(true);
            } else if (data.error) {
                throw new Error(data.error);
            } else if (data.message) {
                throw new Error(data.message);
            } else {
                throw new Error("Serverio klaida.");
            }
        })
        .catch(error => {
            setLoadComplete(true);
            setLoadError(`Nepavyko pakrauti vartotojų: ${error}`);
        })
    }, [refreshData]);

    const removeUser = (id) => {

        if (!window.confirm("Ar tikrai norite ištrinti šį vartotoją?")) {
            return;
        }

        setRemoveError("");

        const handled_responses = [400, 401, 404, 500, 503];

        remove("/user/delete.php", encodeFormData({id: id, jwt: props.auth.JWT}))
        .then(response => {

            if (response.ok && response.status === 204) {
                setRefreshData(!refreshData);

            } else if (handled_responses.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unrecognized response code: ${response.status}`);
            }
        })
        .then(data => {

            if (!data) {
                return;
            }

            if (data.code === 400) {
                setRemoveError("Klaidinga užklausa.");
            } else if (data.code === 401) {
                setRemoveError("Klaidingi prisijungimo duomenys.");
            } else if (data.code === 404) {
                setRemoveError(`Kategorija kurios id yra ${id} nerasta duomenų bazėje.`);
            } else if (data.code === 500) {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Serverio klaida.");
                }
            } else {
                setRemoveError("Serverio klaida.");
            }
        })
        .catch(error => {
            setRemoveError(`Įvyko netikėta klaida: ${error}`);
        })

    }

    const setAdmin = (id, event) => {

        setAdminUpdateWorking([...adminUpdateWorking, id]);

        const userData = users.find(user => user.id === id);
        const userIndex = users.indexOf(userData);
        const isAdmin = event.target.checked;
        const handled_responses = [400, 401, 500, 503];


        putForm("/user/set_admin.php", encodeFormData({id: id, is_admin: isAdmin ? 1 : 0, jwt: props.auth.JWT}))
        .then(response => {

            if (response.ok && response.status === 200) {
                userData.is_admin = isAdmin;
                return;
            }

            event.target.checked = !isAdmin;
            userData.is_admin = !isAdmin;

            if (handled_responses.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unexpected response code ${response.status}`);
            }
        })
        .then(data => {

            if (!data) {
                return;
            }

            if (data.error) {
                throw new Error(data.error);
            } else if (data.message) {
                throw new Error(data.message);
            } else {
                throw new Error(data.code);
            }
        })
        .catch(error => {
            event.target.checked = !isAdmin;
            userData.is_admin = !isAdmin;
            setAdminUpdateError(`Netikėta klaida: ${error}`);
        })
        .finally(() => {
            const updatedUsers = [...users];
            updatedUsers[userIndex] = userData;
            setUsers(updatedUsers);
            setAdminUpdateWorking(adminUpdateWorking.filter(workingId => workingId !== id));
        });

    }

    return (
        <UsersPageComponent
            users={users}
            loadComplete={loadComplete}
            removeUser={removeUser}
            removeError={removeError}
            setAdmin={setAdmin}
            adminUpdateError={adminUpdateError}
            loadError={loadError}
            adminUpdateWorking={adminUpdateWorking}
        />
    );
}