import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import App from "./app/App";
import store from "./app/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("app")
);