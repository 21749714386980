import "../AdminStyle.css";
import "./GenericStyle.css";
import React from "react";
import * as Icon from "react-bootstrap-icons";

export default function DataCard( {props, data, onEdit, onRemove} ) {
  return (
    <div className="data-card">

      <div className="card-title">
        <h6>{data.name}</h6>
      </div>

      <div className="card-buttons">

        <button className="btn btn-primary m-r-1em" onClick={onEdit}>
          <span className="button-icon"><Icon.PencilSquare /></span>
          <span className="button-text">REDAGUOTI</span>
        </button>

        <button className="btn btn-danger m-r-1em" onClick={onRemove}>
          <span className="button-icon"><Icon.Trash /></span>
          <span className="button-text">IŠTRINTI</span>
        </button>

      </div>

    </div>
  );
}