import "../AdminStyle.css";
import "./ProductsStyle.css";
import React from "react";
import _ from "lodash";

import ProductCardAdminComponent from "./ProductCardAdminComponent";
import LoadingComponent from "../../shared/loading/LoadingComponent";
import NoProductsFoundComponent from "../../shared/error/NoProductsFoundComponent";

export default function ProductViewComponent({
    props,
    products,
    openProduct,
    removeProduct,
    loadComplete,
    productCardRefs,
}) {

  return (
    loadComplete ?
      !_.isEmpty(products) ?
        <div className="products-container">
          {products.map((product, idx) => {
            return (
              <ProductCardAdminComponent
                key={idx}
                product={product}
                productCardRefs={productCardRefs}
                openProduct={openProduct}
                removeProduct={removeProduct}
              />
            );

          })}
        </div> :
        <NoProductsFoundComponent /> :
      <LoadingComponent />
  );
}