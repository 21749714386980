import React from "react";
import FooterComponent from "../../../components/guest/footer/FooterComponent";
import _ from "lodash";

export default function Footer( {pageData, footerRef} ) {

    const address = [
        pageData.address_line_1,
        pageData.address_line_2,
        pageData.address_line_3].filter(line => (
          !_.isEmpty(line)
        )).join(", ");

    return (
        <FooterComponent
            pageData={pageData}
            footerRef={footerRef}
            address={address}
        />
    );
}