export function pagingDataToPages (pagingData) {
    if (!pagingData.pages) return [1];

    var pages = [];
    const received_pages = pagingData.pages.map(page => parseInt(page.page));

    if (received_pages[0] !== 1) pages.push(1);
    if (received_pages[0] > 2) pages.push("...");

    pages = pages.concat(received_pages);

    if (received_pages[received_pages.length - 1] !== pagingData.number_of_pages) {
        if (received_pages[received_pages.length - 1] !== (pagingData.number_of_pages - 1)) {
            pages.push("...");
        }

        pages.push(pagingData.number_of_pages);
    }

    return pages;
}