import React from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

import "./ErrorStyle.css";
import ROUTES from "../../../app/routes";

export default function NotFoundPage({props, backTo=ROUTES.guestPage()}) {
    return (
        <section className="container not-found-error">
            <div className="content">
                <h1>404 NOT FOUND</h1>

                <div className="error-icon">
                    <Icon.EmojiDizzy />
                </div>

                <h1>APGAILESTAUJAME</h1>
                <h1>NEPAVYKO RASTI TOKIO PUSLAPIO</h1>

                <div className="error-page-button">
                    <Link to={backTo} >
                        <button className="btn btn-primary m-r-1em">
                            <Icon.ChevronLeft className="button-icon" />
                            GRĮŽTI Į PAGRINDINĮ
                        </button>
                    </Link>
                </div>

            </div>
        </section>
    );
}