import React from "react";

import "./ErrorStyle.css";

export default function ErrorPage ({ errorMessage, goBack }) {
  return(
    <main role="main" className="container starter-template">

      <div className="row">
        <div className="col">

          <div id="content">

            <h2>Įvyko Klaida</h2>

            <div className='alert alert-danger'>{errorMessage}</div>

            <button className="btn btn-primary" onClick={goBack}>Grįžt atgal</button>

          </div>
        </div>
      </div>
    </main>
  );
}