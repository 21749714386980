import React, {useState} from "react";

import HeaderComponent from "../../../components/admin/header/HeaderComponent";

export default function Header( {props, authProps} ) {
    const [showUserOptions, setShowUserOptions] = useState(false);

    return (
        <HeaderComponent
            props={props}
            authProps={authProps}
            showUserOptions={showUserOptions}
            setShowUserOptions={setShowUserOptions}
        />
    );
}