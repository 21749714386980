import React from "react";

import "./LoadingStyle.css";

export default function FullScreenLoadingPage ({ props, hide }) {
  return (
    <div className={`loading-page full-screen ${hide? "smooth-hide shrink" : ""}`}>

      <div className="content container">
        <img
          className={hide ? "loading-logo-shrink" : ""}
          src="/graphics/logos/audriaus_baldai_logo.png"
          width="300"
          heigh="300"
          alt=""
        />
      </div>

    </div>
  );
}