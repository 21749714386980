import React, {useState, useEffect} from "react";
import _ from "lodash";

import { getFormData } from "../../../utils/form";
import { get, postFormData } from "../../../utils/requests";
import ProfileEditPageComponent from "../../../components/admin/users/ProfileEditPageComponent";

export default function ProfileEditPage( props ) {

    const [loadError, setLoadError] = useState("");
    const [updateError, setUpdateError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [emailRep, setEmailRep] = useState("");
    const [emailAlert, setEmailAlert] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordRep, setPasswordRep] = useState("");
    const [passwordAlert, setPasswordAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationPass, setConfirmationPass] = useState("");
    const [profilePictureURL, setProfilePictureURL] = useState("");

    useEffect(() => {
        get("user/read_one.php", {jwt: props.auth.JWT, id: props.auth.userData.id})
        .then(response => {
            return response.json();
        })
        .then(data => {

            if (_.isEmpty(data)) {
                throw new Error("Nėra duomenų");
            }

            if (data.code === 200 && !_.isEmpty(data.payload)) {
                setFirstname(data.payload.firstname);
                setLastname(data.payload.lastname);
                setProfilePictureURL(data.payload.profile_picture);
                setIsLoading(false);
            } else if (data.error) {
                throw new Error(data.error);
            } else {
                throw new Error("Nepavyko pakrauti duomenų");
            }

        })
        .catch(error => {
            setIsLoading(false);
            setLoadError(`Netikėta klaida: ${error.message}`);
        })

    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setEmailAlert(false);
        setPasswordAlert(false);
        setUpdateError("");
        setSuccessMessage("");

        var emailError = false;
        var passError = false;

        if (!checkRep(email, emailRep)) {
            setEmailAlert(true);
            emailError = true;
        }

        if (!checkRep(password, passwordRep)) {
            setPasswordAlert(true);
            passError = true;
        }

        if (emailError || passError) {
            return;
        }

        const updateData = {
            jwt: props.auth.JWT,
            id: props.auth.userData.id,
            cfm_password: confirmationPass,
        }

        if (_.isString(firstname) && !_.isEmpty(firstname)) {
            updateData.firstname = firstname;
        }

        if (_.isString(lastname) && !_.isEmpty(lastname)) {
            updateData.lastname = lastname;
        }

        if (!_.isEmpty(email)) {
            updateData.email = email;
        }

        if (!_.isEmpty(password)) {
            updateData.password = password;
        }

        postFormData("/user/update_user.php", getFormData(updateData))
        .then(response => {
            try {
                return response.json();
            } catch (error) {
                throw new Error("Užklausos atsako duomenys yra nenumatyti");
            }
        })
        .then(data => {

            if (data.code === 200) {
                setSuccessMessage("Duomenys sėkmingai atnaujinti!");
            } else if (data.error) {
                throw new Error(data.error);
            } else {
                setUpdateError("Nepavyko atnaujinti duomenų.");
            }

        })
        .catch(error => {
            setUpdateError(`Netikėta klaida: ${error.message}`)
        })

    }

    const checkRep = (check, checkRep) => {
        if (!_.isEmpty(check) && !_.isEmpty(checkRep) && check !== checkRep) {
            return false;
        }

        return true;
    }

    return (
        <ProfileEditPageComponent
            props={props}
            loadError={loadError}
            updateError={updateError}
            successMessage={successMessage}
            handleSubmit={handleSubmit}
            firstname={firstname}
            setFirstname={setFirstname}
            lastname={lastname}
            setLastname={setLastname}
            email={email}
            setEmail={setEmail}
            emailRep={emailRep}
            setEmailRep={setEmailRep}
            emailAlert={emailAlert}
            password={password}
            setPassword={setPassword}
            passwordRep={passwordRep}
            setPasswordRep={setPasswordRep}
            passwordAlert={passwordAlert}
            isLoading={isLoading}
            confirmationPass={confirmationPass}
            setConfirmationPass={setConfirmationPass}
        />
    );
}