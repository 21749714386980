import React from "react";

export default function Spinner () {
    return (
        <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
        ></span>
    );
}