import React, { useState, useEffect } from "react";
import CategoryModifyComponent from "../../../components/admin/categories/CategoryModifyComponent";
import { get, putForm } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";

export default function CategoryModifyPage( props ) {
    const [categoryLoaded, setCategoryLoaded] = useState(false);
    const [category, setCategory] = useState(null);
    const [name, setName] = useState("");
    const [id, setId] = useState();
    const [categoryError, setCategoryError] = useState();
    const [updateError, setUpdateError] = useState();
    const [working, setWorking] = useState(false);

    useEffect(() => {
        setCategoryLoaded(false);

        get("/category/read_one.php", {id: props.match.params.id}, true)
        .then(response => {

            setCategoryLoaded(true);

            const handled_requests = [200, 400, 404, 500];

            if (handled_requests.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unexpected response: ${response.status}`);
            }
        })
        .then(data => {

            const category_data = data.payload;

            if (data.code === 200) {
                setCategory(category_data);
                setName(category_data.name);
                setId(category_data.id);
                return category_data;
            } else if (data.code === 400) {
                setCategoryError("Klaidinga užklausa");
            } else if (data.code === 404) {
                setCategoryError("Kategorija nerastas");
            } else if (data.code === 500) {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("500: Internal Server Error");
                }
            }
            else {
                throw new Error("Faulty response data");
            }

        })
        .catch(e => {
            setCategoryError("Netikėta klaida: " + e.message);
        })

    }, []);

    function handleSubmit(e) {
        e.preventDefault();

        var data = {
            id: id,
            name: name,
            jwt: props.auth.JWT
        }

        setWorking(true);

        putForm("category/update.php", encodeFormData(data))
        .then(response => {
            setWorking(false);

            if (response.ok && response.status === 200) {
                //return response.json();
                props.history.goBack();
            }
        })
        .catch(e => {
            setUpdateError("Netikėta klaida: " + e.message);
        });
    }

    return (
        <CategoryModifyComponent
            category={category}
            name={name}
            setName={setName}
            handleSubmit={handleSubmit}
            categoryLoaded={categoryLoaded}
            updateError={updateError}
            categoryError={categoryError}
            working={working}
            props={props}
        />
    );
}