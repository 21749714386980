import React, { useState, useEffect } from "react";
import PageInfoComponent from "../../../components/admin/pageInfo/PageInfoComponent";
import { get, postForm } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";

export default function PageInfoPage( props ) {
    const [loadComplete, setLoadComplete] = useState(false);
    const [loadError, setLoadError] = useState("");
    const [updateError, setUpdateError] = useState("");
    const [working, setWorking] = useState(false);
    const [success, setSuccess] = useState("");
    const [pageName, setPageName] = useState();
    const [pageDescription, setPageDescription] = useState();
    const [contactPerson, setContactPerson] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [email, setEmail] = useState();
    const [addressLine1, setAddressLine1] = useState();
    const [addressLine2, setAddressLine2] = useState();
    const [addressLine3, setAddressLine3] = useState();
    const [googleMapsLink, setGoogleMapsLink] = useState();

    useEffect(() => {
        setLoadComplete(false);

        get("/pageInfo/read.php", {}, true)
        .then(response => {

            const handled_responses = [200, 400, 401, 404, 500];

            if (handled_responses.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unrecognized response code ${response.status}`);
            }
        })
        .then(data => {
            if (!data) return;

            if (data.code === 200) {
                const infoData = data.payload;
                setPageName(infoData.page_name);
                setPageDescription(infoData.page_description);
                setContactPerson(infoData.contact_person);
                setPhoneNumber(infoData.phone_number);
                setEmail(infoData.email);
                setAddressLine1(infoData.address_line_1);
                setAddressLine2(infoData.address_line_2);
                setAddressLine3(infoData.address_line_3);
                setGoogleMapsLink(infoData.google_maps_link);

            } else if (data.error) {
                throw new Error(data.error);
            } else if (data.message) {
                throw new Error(data.message);
            } else {
                throw new Error("Serverio klaida.");
            }
        })
        .catch(error => {
            setLoadError(`Nepavyko pakrauti informacijos: ${error}`);
        })
        .finally(() => {
            setLoadComplete(true);
        })

    }, []);

    const update = () => {

        setWorking(true);

        const infoData = JSON.stringify({
            page_name: pageName,
            page_description: pageDescription,
            contact_person: contactPerson,
            phone_number: phoneNumber,
            email: email,
            address_line_1: addressLine1,
            address_line_2: addressLine2,
            address_line_3: addressLine3,
            google_maps_link: googleMapsLink,
        });

        var data = {
            info_data: infoData,
            jwt: props.auth.JWT,
        }

        postForm("/pageInfo/save_info.php", encodeFormData(data))
        .then(response => {

            setWorking(false);

            const handled_responses = [200, 400, 401, 404, 500];

            if (handled_responses.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unrecognized response code ${response.status}`);
            }
        })
        .then(data => {

            if (data.code === 200) {
                setSuccess("Puslapio informacija sėkmingai atnaujinta");
            } else if (data.error) {
                throw new Error(data.error);
            } else if (data.message) {
                throw new Error(data.message);
            } else {
                throw new Error("Serverio klaida.");
            }
        })
        .catch(error => {
            setUpdateError(`Nepavyko atnaujinti informacijos: ${error}`);
        })
        .finally(() => {
            setWorking(false);
        })

    }

    return (
        <PageInfoComponent
            props={props}
            loadComplete={loadComplete}
            loadError={loadError}
            updateError={updateError}
            working={working}
            success={success}
            update={update}
            pageName={pageName}
            setPageName={setPageName}
            pageDescription={pageDescription}
            setPageDescription={setPageDescription}
            contactPerson={contactPerson}
            setContactPerson={setContactPerson}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            email={email}
            setEmail={setEmail}
            addressLine1={addressLine1}
            setAddressLine1={setAddressLine1}
            addressLine2={addressLine2}
            setAddressLine2={setAddressLine2}
            addressLine3={addressLine3}
            setAddressLine3={setAddressLine3}
            googleMapsLink={googleMapsLink}
            setGoogleMapsLink={setGoogleMapsLink}
        />
    );
}



















