import React, { useState, useEffect } from "react";
import ProductInfoComponent from "../../../components/admin/products/ProductInfoComponent";
import { get } from "../../../utils/requests";

export default function ProductInfoPage( props ) {
    const [productComplete, setProductComplete] = useState(false);
    const [categoriesComplete, setCategoriesComplete] = useState(false);
    const [product, setProduct] = useState(null);
    const [categories, setCategories] = useState({});
    const [error, setError] = useState("");
    const [conditions, setConditions] = useState({});
    const [conditionsLoaded, setConditionsLoaded] = useState(false);

    useEffect(() => {
        setProductComplete(false);
        setCategoriesComplete(false);

        get("/product/read_one.php", {id: props.match.params.id}, true)
        .then(response => {

            setProductComplete(true);

            const handled_requests = [200, 400, 404, 500];

            if (handled_requests.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unexpected response: ${response.status}`);
            }
        })
        .then(data => {

            if (data.code === 200 && data.payload) {
                setProduct(data.payload);
            } else if (data.code === 400) {
                setError("Klaidinga užklausa");
            } else if (data.code === 404) {
                setError("Produktas nerastas");
            } else if (data.code === 500) {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("500: Internal Server Error");
                }
            }
            else {
                throw new Error("Faulty response data");
            }
        })
        .catch(e => {
            setError("Netikėta klaida: " + e.message);
        })

        get("/category/read.php", {}, true)
        .then(response => {

            setCategoriesComplete(true);

            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            const _categories = {"-1": "Nenurodyta"};

            data.payload.map(element => {
                _categories[element.id] = element.name;
            });
            setCategories(_categories);
        })
    }, []);

    useEffect(() => {
        setConditionsLoaded(false);

        get("/condition/read.php", {}, true)
        .then(response => {
            setConditionsLoaded(true);
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            const _conditions = {};
            _conditions[-1] = "Nenurodyta";

            data.payload.map(element => {
                _conditions[element.id] = element.name;
            });
            setConditions(_conditions);
        })
    }, []);

    return (
        <ProductInfoComponent
            product={product}
            categories={categories}
            conditions={conditions}
            productComplete={productComplete}
            categoriesComplete={categoriesComplete}
            conditionsLoaded={conditionsLoaded}
            error={error}
            props={props}
        />
    );
}