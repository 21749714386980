import { postForm } from "./requests";
import { encodeFormData } from "./form";
import { setJWT, setLoggedIn, setLoginComplete, setUserData } from "../features/auth/authSlice";

export function checkLogin(dispatch, auth) {

    postForm("user/validate_token.php", encodeFormData({jwt: auth.JWT}))
    .then(response => {

        if (!response.ok) {
            throw new Error("HTTP status " + response.status);
        }

        return response.json();
    })
    .then(data => {

        if (data.payload === undefined) {
            throw new Error("Data is missing.");
        }

        if (data.payload.type !== "login") {
            throw new Error("Wrong token type.");
        }

        dispatch(setUserData({
            firstname: data.payload.firstname,
            lastname: data.payload.lastname,
            email: data.payload.email,
            id: data.payload.id,
            profile_picture: data.payload.profile_picture,
        }));
        dispatch(setLoggedIn(true));
        dispatch(setLoginComplete(true));
    })
    .catch(error => {
        dispatch(setUserData({}));
        dispatch(setLoggedIn(false));
        dispatch(setLoginComplete(true));
    })
}

export function logOut(dispatch) {
    dispatch(setLoggedIn(false));
    dispatch(setLoginComplete(true));
    dispatch(setUserData({}));
    dispatch(setJWT(null));
}

export async function validateToken(token, expectedType) {

    const response = await postForm("user/validate_token.php", encodeFormData({jwt: token}))

    if (!response.ok || response.status !== 200) {
        return false;
    }

    const json = await response.json();

    if (!json.payload || json.payload.type !== expectedType) {
        return false;
    }

    return true;

}