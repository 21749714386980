import React, { useState, useEffect } from "react";
import ConditionsPageComponent from "../../../components/admin/conditions/ConditionsPageComponent";
import { get, remove } from "../../../utils/requests";
import { encodeFormData } from "../../../utils/form";

export default function ConditionPage( props ) {
    const [loadComplete, setLoadComplete] = useState(false);
    const [conditions, setConditions] = useState([]);
    const [removeError, setRemoveError] = useState("");
    const [refreshData, setRefreshData] = useState(false);

    useEffect(() => {
        setLoadComplete(false);

        get("/condition/read.php", {}, true)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            if (data) {
                setConditions(data.payload);
            }
            setLoadComplete(true);
        })
        .catch(error => {
            setLoadComplete(true);
        });
    }, [refreshData]);

    const removeCondition = (id) => {

        if (!window.confirm("Ar tikrai norite ištrinti šią būklę?")) {
            return;
        }

        setRemoveError("");

        const handled_responses = [400, 401, 404, 500, 503];

        remove("/condition/delete.php", encodeFormData({id: id, jwt: props.auth.JWT}))
        .then(response => {

            if (response.ok && response.status === 204) {
                setRefreshData(!refreshData);
            } else if (handled_responses.includes(response.status)) {
                return response.json();
            } else {
                throw new Error(`Unrecognized response code: ${response.status}`);
            }
        })
        .then(data => {

            if (!data) {
                return;
            }

            if (data.code === 400) {
                setRemoveError("Klaidinga užklausa.");
            } else if (data.code === 401) {
                setRemoveError("Klaidingi prisijungimo duomenys.");
            } else if (data.code === 404) {
                setRemoveError(`Būklė kurios id yra ${id} nerasta duomenų bazėje.`);
            } else if (data.code === 500) {
                if (data.error) {
                    throw new Error(data.error);
                } else {
                    throw new Error("Serverio klaida.");
                }
            } else {
                setRemoveError("Serverio klaida.");
            }
        })
        .catch(error => {
            setRemoveError(`Įvyko netikėta klaida: ${error}`);
        })

    }

    return (
        <ConditionsPageComponent
            props={props}
            conditions={conditions}
            loadComplete={loadComplete}
            removeCondition={removeCondition}
            removeError={removeError}
        />
    );
}