import "../AdminStyle.css";
import "./AuthStyle.css";
import React from "react";

export default function LoginForm ({
   handleSubmit,
   email,
   setEmail,
   password,
   setPassword,
   errorMessage,
   isLoading,
}) {
  return(
    <main role="main" className="container starter-template">

      <div className="row">
        <div className="col">

          <div id="response">
            {errorMessage ? <div className='alert alert-danger'>{errorMessage}</div> : ""}
          </div>

          <div id="content">

            <h2>Prisijungimas</h2>

            <form onSubmit={handleSubmit} id="login_form">

              <div className="form-floating mb-3">
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  placeholder="El. pašto adresas"
                  maxLength="64"
                  className="form-control"
                  required
                />
                <label htmlFor="email">El. pašto adresas</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  placeholder="Slaptažodis"
                  className="form-control"
                  required
                />
                <label htmlFor="password">Slaptažodis</label>
              </div>

              <button className="btn btn-primary" disabled={isLoading}>
                  {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ""}
                  <span className="font-weight-normal">Prisijungti</span>
              </button>

            </form>
          </div>
        </div>
      </div>
    </main>
  );
}