import React from "react";
import * as Icon from "react-bootstrap-icons";

import Spinner from "../../shared/utils/Spinner";

export default function ProfileEditPageComponent ({
  props,
  loadError,
  updateError,
  successMessage,
  handleSubmit,
  firstname,
  setFirstname,
  lastname,
  setLastname,
  email,
  setEmail,
  emailRep,
  setEmailRep,
  emailAlert,
  password,
  setPassword,
  passwordRep,
  setPasswordRep,
  passwordAlert,
  isLoading,
  confirmationPass,
  setConfirmationPass,
}){
  return (
    <div className="container container-admin">

      <div className="page-header">
        <h1>REDAGUOTI PASKYRĄ</h1>

        <div id="response">
          {loadError ? <div className='alert alert-danger'>{loadError}</div> : ""}
          {updateError ? <div className='alert alert-danger'>{updateError}</div> : ""}
          {successMessage ? <div className='alert alert-success'>{successMessage}</div> : ""}
        </div>

        <hr />

      </div>

      <form onSubmit={handleSubmit} id="profile-edit-form">

        <div className="profile-edit form-group">

          <h5>KEISTI VARDĄ, PAVARDĘ</h5>

          <div className="form-floating mb-3">
            <input
              id="reg_firstname"
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.currentTarget.value)}
              placeholder="Vardas"
              minLength="3"
              maxLength="64"
              className="form-control"
              required
            />
            <label htmlFor="reg_firstname">Vardas</label>
          </div>

          <div className="form-floating mb-3">
            <input
              id="reg_lastname"
              type="text"
              value={lastname}
              onChange={(e) => setLastname(e.currentTarget.value)}
              placeholder="Pavardė"
              minLength="2"
              maxLength="64"
              className="form-control"
              required
            />
            <label htmlFor="reg_lastname">Pavardė</label>
          </div>

        </div>

        <div className="profile-edit form-group">

          <h5>KEISTI EL. PAŠTĄ</h5>

          <div className="form-floating mb-3">
            <input
              id="reg_email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              placeholder="Naujas el. pašto adresas"
              maxLength="64"
              className={emailAlert ? "form-control alert-danger" : "form-control"}
            />
            <label htmlFor="reg_email">Naujas el. pašto adresas</label>
          </div>

          <div className="form-floating mb-3">
            <input
              id="reg_rep_email"
              type="email"
              value={emailRep}
              onChange={(e) => setEmailRep(e.currentTarget.value)}
              placeholder="Pakartokite naują el. pašto adresą"
              maxLength="64"
              className={emailAlert ? "form-control alert-danger" : "form-control"}
            />
            <label htmlFor="reg_email">Pakartokite naują el. pašto adresą</label>
          </div>

        </div>

        <div className="profile-edit form-group">

          <h5>KEISTI SLAPTAŽODĮ</h5>

          <div className="form-floating mb-3">
            <input
              id="reg_password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              placeholder="Naujas slaptažodis"
              minLength="8"
              className={passwordAlert ? "form-control alert-danger" : "form-control"}
              />
            <label htmlFor="password">Naujas slaptažodis</label>
          </div>

          <div className="form-floating mb-3">
            <input
              id="reg_rep_password"
              type="password"
              value={passwordRep}
              onChange={(e) => setPasswordRep(e.currentTarget.value)}
              placeholder="Pakartokite naują slaptažodį"
              minLength="8"
              className={passwordAlert ? "form-control alert-danger" : "form-control"}
            />
            <label htmlFor="password">Pakartokite naują slaptažodį</label>
          </div>

        </div>

        <div className="profile-edit form-group">

          <h5>PATVIRTINKITE SLAPTAŽODŽIU</h5>

          <div className="form-floating mb-3">
            <input
              id="cfm_password"
              type="password"
              value={confirmationPass}
              onChange={(e) => setConfirmationPass(e.currentTarget.value)}
              placeholder="Slaptažodis"
              minLength="8"
              className="form-control"
              required
              />
            <label htmlFor="password">Slaptažodis</label>
          </div>

        </div>

        <div id="btn-div">
          <button
            className="btn btn-secondary"
            onClick={props.history.goBack}
            type="button"
          >
            <Icon.ChevronLeft className="button-icon" />
            <span>GRĮŽTI</span>
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {isLoading ? <Spinner /> : ""}
            <span className="font-weight-normal">PATVIRTINTI</span>
          </button>
        </div>

      </form>

    </div>
  );
}