import React from "react";

import "./UtilsStyle.css";

export default function ListSelect({onSelect, title, options, show, currentItem}) {

  return (
    <div className={`list-select ${(show ? "show-options" : "")}`}>
      <div className="title-bar">
        <h6>{title}</h6>
      </div>
      <hr/>

      <div className="option-list">
        <ul>
        {
          options.map(item => (
              <li
                id={item.id}
                key={item.id}
                onClick={e => onSelect(e.target.id)}
                className={item.id == currentItem ? "item-selected" : ""}
              >
                {item.name.toUpperCase()}
              </li>
          ))

        }
        </ul>
      </div>

    </div>
  );
}