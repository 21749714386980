import "../AdminStyle.css";
import "./ConditionsStyle.css";
import React from "react";

import ConditionInfoForm from "./ConditionInfoForm";
import LoadingComponent from "../../shared/loading/LoadingComponent";

export default function ConditionModifyComponent({
  condition,
  name,
  setName,
  handleSubmit,
  conditionLoaded,
  updateError,
  conditionError,
  working,
  props,
})
{

  return (
    <div className="container container-admin">

      <div id="response">
        {conditionError ? <div className='alert alert-danger'>{conditionError}</div> : ""}
      </div>

      <div id="response">
        {updateError ? <div className='alert alert-danger'>{updateError}</div> : ""}
      </div>

      {
        (conditionLoaded) || conditionError ?
          conditionLoaded && condition ?
            <>
              <div className="page-header">
                <h2>BŪKLĖS REDAGAVIMAS</h2>
                <hr/>
              </div>

              <ConditionInfoForm
                name={name}
                setName={setName}
                handleSubmit={handleSubmit}
                conditionLoaded={conditionLoaded}
                working={working}
                props={props}
              />

              <footer className="admin-spacer" />
            </>:
              <div className="page-header">
                <h2>Kategorija Nerasta</h2>
                <button className="btn btn-primary" onClick={props.history.goBack}>Grįžt atgal</button>
              </div> :
        <LoadingComponent />
      }

    </div>
  );

}