import React, { useState, useEffect } from "react";
import ProductPreviewPageComponent from "../../../components/guest/product/ProductPreviewPageComponent";
import { get } from "../../../utils/requests";

export default function ProductPreviewPage({ props, goToHeader }) {
    const [product, setProduct] = useState();
    const [loadComplete, setLoadComplete] = useState(false);
    const [loadError, setLoadError] = useState("");
    const [conditions, setConditions] = useState({});
    const [categories, setCategories] = useState({});
    const productId = props.match.params.id;

    useEffect(() => {window.scrollTo(0,0)}, []);

    useEffect(() => {
        setLoadComplete(false);

        get("/product/read_one.php", {id: productId}, true)
        .then(response => {

            setLoadComplete(true);

            if (response.ok && response.status === 200) {
                return response.json();
            } else {
                setLoadError("Nepavyko pakrauti prekės duomenų");
            }
        })
        .then(data => {
            if (!data) { return; }
            setProduct(data.payload);
        })
        .catch(e => {
            setLoadError(e.message);
        })

    }, []);

    useEffect(() => {
        get("/condition/read.php", {}, true)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {

            if (data && data.payload) {
                var _conditions = [...data.payload, {id: -1, name: "BE BŪKLĖS"}];
                var _dict = {}

                _conditions.forEach(e => {
                    _dict[e.id] = e.name;
                });

                setConditions(_dict);
            }
        })

        get("category/read.php", {}, true)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {

            if (data && data.payload) {
                var _categories = [...data.payload, {id: -1, name: "BE KATEGORIJOS"}];
                var _dict = {}

                _categories.forEach(e => {
                    _dict[e.id] = e.name;
                });

                setCategories(_dict);
            }

        })

    }, []);

    return (
        <ProductPreviewPageComponent
            props={props}
            product={product}
            loadComplete={loadComplete}
            loadError={loadError}
            conditions={conditions}
            categories={categories}
        />
    );
}