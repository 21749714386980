import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const productSorting = createSlice({
    name: "productSorting",
    initialState: {
        sorting: [
            {id: 0, name: "PATALPINTA, NAUJAUSIAS - SENIAUSIAS"},
            {id: 1, name: "PATALPINTA, SENIAUSIAS - NAUJAUSIAS"},
            {id: 2, name: "KAINA, MAŽIAUSIA - DIDŽIAUSIA"},
            {id: 3, name: "KAINA, DIDŽIAUSIA - MAŽIAUSIA"},
            {id: 4, name: "PAVADINIMAS, A - Z"},
            {id: 5, name: "PAVADINIMAS, Z - A"},
        ],
        sortBy: 0,
    },
    reducers: {
        setSortBy: (state, action) => {
            state.sortBy = _.parseInt(action.payload);
        },
    }
});

export const selectProductSorting = (state) => state.productSorting.sorting;
export const selectSortBy = (state) => state.productSorting.sortBy;
export default productSorting.reducer;
export const { setSortBy } = productSorting.actions;