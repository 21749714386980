//const adminApiRoot = "https://audriaus-baldai.lt/api/admin/v1/";
//const guestApiRoot = "https://audriaus-baldai.lt/api/guest/v1/";
const adminApiRoot = "https://audriaus-baldai.lt/api/admin/v1/";
const guestApiRoot = "https://audriaus-baldai.lt/api/guest/v1/";

export function postForm(url, formData, guest=false) {

    const apiRoot = guest ? guestApiRoot : adminApiRoot;

    return fetch(apiRoot + url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: formData
    });
}

export function postFormData(url, formData, guest=false) {
    const apiRoot = guest ? guestApiRoot : adminApiRoot;

    return fetch(apiRoot + url, {
        method: "POST",
        body: formData
    });
}

export function putForm(url, formData, guest=false) {

    const apiRoot = guest ? guestApiRoot : adminApiRoot;

    return fetch(apiRoot + url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: formData
    });
}

export function get(url, queries={}, guest=false) {

    const apiRoot = guest ? guestApiRoot : adminApiRoot;
    var requestUrl = apiRoot + url;

    if (queries) {
        requestUrl += "?";
    }

    Object.keys(queries).map((key, idx) => {
        return requestUrl += (idx > 0 ? "&" : "") + key + "=" + queries[key];
    });

    return fetch(requestUrl, {
        method: "GET"
    });
}

export function remove(url, formData, guest=false) {

    const apiRoot = guest ? guestApiRoot : adminApiRoot;

    return fetch(apiRoot + url, {
        method: "DELETE",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: formData
    });
}