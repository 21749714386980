import React, {useState, useEffect, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import ROUTES from "../../../app/routes";
import { get } from "../../../utils/requests";
import { pagingDataToPages } from "../../../utils/paging";
import ProductsPageComponent from "../../../components/guest/product/ProductsPageCompoenent";
import { selectScrollHistory, setHistory } from "../../../features/history/scrollHistorySlice";
import { selectSortBy, setSortBy, selectProductSorting } from "../../../features/sorting/productSortingSlice";
import { selectProductCondition, selectProductConditions, setProductCondition, setProductConditions } from "../../../features/conditions/productConditionsSlice";
import { selectProductCategories, setProductCategories } from "../../../features/categories/productCategoriesSlice";
import { __ } from "../../../utils/lolodash";

export default function ProductsPage( {props, pageData} ) {

    const [loadComplete, setLoadComplete] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [products, setProducts] = useState([]);
    const [paging, setPaging] = useState({});
    const [pages, setPages] = useState([1]);
    const [showCategories, setShowCategories] = useState(false);
    const [showConditions, setShowConditions] = useState(false);
    const [showSorting, setShowSorting] = useState(false);

    const currentPage = parseInt(props.match.params.page ? props.match.params.page : 1);
    const category = parseInt(props.match.params.category ? props.match.params.category : 0)

    const productCardRefs = useRef({});

    const dispatch = useDispatch();
    const scrollHistory = useSelector(selectScrollHistory);
    const sorting = useSelector(selectProductSorting);
    const sortBy = useSelector(selectSortBy);
    const conditions = useSelector(selectProductConditions);
    const condition = useSelector(selectProductCondition);
    const categories = useSelector(selectProductCategories);

    useEffect(() => {
        if (!showCategories) return;

        if (showConditions) setShowConditions(false);
        if (showSorting) setShowSorting(false);

    }, [showCategories]);

    useEffect(() => {
        if (!showConditions) return;

        if (showCategories) setShowCategories(false);
        if (showSorting) setShowSorting(false);

    }, [showConditions]);

    useEffect(() => {
        if (!showSorting) return;

        if (showCategories) setShowCategories(false);
        if (showConditions) setShowConditions(false);

    }, [showSorting]);

    useEffect(() => {
        if (showSorting) setShowSorting(false);
    }, [sortBy]);

    useEffect(() => {
        if (showConditions) {
            setShowConditions(false);
            props.history.push(ROUTES.productsListRoute(category, 1));
        }
    }, [condition]);

    useEffect(() => {
        productCardRefs.current = {...productCardRefs.current};
    }, [products]);

    useEffect(() => {

        const scrollRef = scrollHistory.history[props.location.key];

        if (scrollRef) {
            const relevantRef = productCardRefs.current[scrollRef];
            if (!relevantRef) return;
            relevantRef.scrollIntoView({block: "center", behavior: "auto"});
        } else {
            window.scrollTo(0,0);
        }

    }, [products])


    useEffect(() => {

        setLoadComplete(false);
        setProducts([...Array(pageData.records_per_page)].map(e => ({})));

        get("/product/read_paging.php", {
            page: currentPage,
            category: category,
            sort_by: sortBy,
            condition: condition,
        }, true)
        .then(response => {

            setLoadComplete(true);

            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            if (data) {
                setProducts(data.payload.records);
                setPaging(data.payload.paging);
            } else {
                setProducts([]);
                setPaging({});
            }
        })

    }, [currentPage, category, sortBy, condition]);

    useEffect(() => {
        setPages(pagingDataToPages(paging));
    }, [paging, category]);

    useEffect(() => {
        get("/category/read.php", {}, true)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            if (data && data.payload) {
                dispatch(setProductCategories(data.payload));
            }
        })
    }, []);

    useEffect(() => {
        get("/condition/read.php", {}, true)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
        })
        .then(data => {
            if (data && data.payload) {
                dispatch(setProductConditions(data.payload));
            }
        })
    }, []);

    const goToPage = (page) => {
        props.history.push(ROUTES.productsListRoute(category, page));
    }

    const goToCategory = (id) => {
        setShowCategories(false);
        props.history.push(ROUTES.productsListRoute(id));
    }

    const openProduct = (id) => {
        dispatch(setHistory({key: props.location.key, id: id}));
        props.history.push(ROUTES.productsViewRoute(id));
    }

    return (
        <ProductsPageComponent
            props={props}
            pageData={pageData}
            products={products}
            loadComplete={loadComplete}
            setRefreshData={setRefreshData}
            paging={paging}
            currentPage={currentPage}
            refreshData={refreshData}
            goTo={goToPage}
            pages={pages}
            openProduct={openProduct}
            productCardRefs={productCardRefs}
            showCategories={showCategories}
            setShowCategories={setShowCategories}
            showConditions={showConditions}
            setShowConditions={setShowConditions}
            showSorting={showSorting}
            setShowSorting={setShowSorting}
            categories={categories}
            conditions={conditions}
            goToCategory={goToCategory}
            category={category}
            sorting={sorting}
            sortBy={sortBy}
            setSortBy={value => dispatch(setSortBy(value))}
            condition={condition}
            setCondition={condition => dispatch(setProductCondition(condition))}
        />
    );
}