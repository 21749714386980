import React from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import LoadingComponent from "../../shared/loading/LoadingComponent";

export default function PrivateRouteComponent( props ) {

  return (
    props.auth.loginComplete ?
      props.auth.loggedIn ?
        <Route {...props} /> :
        <Redirect
          to={{
          pathname: "/admin/login",
          state: { from: props.location.pathname }
          }}
        /> :
      <LoadingComponent />
  );
};